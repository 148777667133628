import React from 'react';
import Layout from '../layout/Layout';
import Settings from './views/settings';

class SettingsPage extends React.Component {
	render() {
		const { history } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
                        <Settings history={history} />
                	</div>
                </main>
             </Layout>
		);
	}
}

export default SettingsPage;
