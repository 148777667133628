import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import { connect } from 'react-redux';
import { userGetStudentSignupSource } from '../../../actions/dashboardActions';
import Notification from '../../common/Notification';

class StudentSignupOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: {},
			data: {},
			total_users: 0,
		};
	}

	getData = () => {
		this.setState({
			isLoading: true,
			notification: {},
		});

		this.props
			.userGetStudentSignupSource()
			.then(({ status, message }) => {
				if (status === 200) {
					this.setState({
						isLoading: false,
						data: message.data,
						total_users: message.total_users,
					});
				} else {
					this.setState({
						isLoading: false,
						notification: {
							text: 'Internal server error. Please try again later.',
							isError: true,
						},
					});
				}
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isLoading: false,
					notification: {
						text: 'Internal server error. Please try again later.',
						isError: true,
					},
				});
			});
	};

	componentDidMount = () => {
		this.getData();
	};

	renderData = () => {
		const { data } = this.state;

		try {
			let dataToShow = [];

			for (var key in data) {
				if (data.hasOwnProperty(key)) {
					dataToShow.push({ hear_from: key, total: data[key] });
				}
			}

			if (dataToShow.length > 0) {
				let result = dataToShow.map((item, idx) => {
					let hear_from_label = item.hear_from.split('_').join(' ');

					if (item.hear_from === '-') {
						hear_from_label = 'not define';
					}

					if (item.hear_from === 'website') {
						hear_from_label = 'old website';
					}

					return (
						<p
							key={`student_hear_from_cat-${idx}`}
							className='mb-0 fs-15 fw-400'>
							{hear_from_label.charAt(0).toUpperCase() +
								hear_from_label.slice(1)}
							<span className='ml-2 font-weight-bold'>
								: {item.total}
							</span>
							<span className='ml-2'>
								{item.total > 1 ? 'persons' : 'person'}
							</span>
						</p>
					);
				});

				return result;
			}
		} catch (error) {
			console.log(error);
		}

		return null;
	};

	render() {
		const { data, total_users, isLoading, notification } = this.state;

		return (
			<div className='px-2'>
				<h5 className='text-center mb-20'>Students hear us from</h5>
				{isLoading ? (
					<div className='h-300px d-flex flex-column align-items-center justify-content-center'>
						<MDSpinner />
						<p className='mt-20 mb-0'>Please wait...</p>
					</div>
				) : !isEmpty(notification) ? (
					<div className='h-300px d-flex flex-column align-items-center justify-content-center'>
						<Notification notification={notification} />
					</div>
				) : (
					<div>
						<hr className='p-0 my-0' />
						<p className='mb-0 py-20 fs-15 fw-400'>
							Total registered
							<span className='ml-2 font-weight-bold'>
								: {total_users}
							</span>
							<span className='ml-2'>
								{total_users > 1 ? 'persons' : 'person'}
							</span>
						</p>
						<hr className='p-0 my-0' />
						{!isEmpty(data) && (
							<div className='mt-20'>{this.renderData()}</div>
						)}
					</div>
				)}
				{!isEmpty(notification) && (
					<div className='h-300px d-flex flex-column align-items-center justify-content-center'>
						<Notification notification={notification} />
					</div>
				)}
			</div>
		);
	}
}

export default connect(null, { userGetStudentSignupSource })(
	StudentSignupOverview
);
