import { API } from '../utils/apiConnect';

export function userGetAllLanguages() {
    return dispatch => {
		return API.get('/common/all-languages')
		.then( res => {
			return res.data;
		})
	}
}

export function userGetAllCountries(data) {
    return dispatch => {
		return API.post('/common/country-selection', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetCityList(data) {
    return dispatch => {
		return API.post('/common/city-list', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetLanguageList(data) {
    return dispatch => {
		return API.post('/common/language-list', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userAddLanguage(data) {
    return dispatch => {
		return API.post('/common/add-language', data )
		.then( res => {
			return res.data;
		})
	}
};

export function userEditLanguage(data) {
    return dispatch => {
		return API.post('/common/edit-language', data )
		.then( res => {
			return res.data;
		})
	}
};

export function userDeleteLanguage(data) {
    return dispatch => {
		return API.post('/common/delete-language', data )
		.then( res => {
			return res.data;
		})
	}
};


export function userDownloadReceipt(data) {
    return dispatch => {
		return API.post('/common/receipt', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userDownloadVoucherReceipt(data) {
    return dispatch => {
		return API.post('/common/receipt-voucher', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userGetAllWorldCountries() {
    return dispatch => {
		return API.get('/common/world-countries')
		.then( res => {
			return res.data;
		})
	}
};

export function userGetCountryDetails(data) {
    return dispatch => {
		return API.post('/common/country-details', data)
		.then( res => {
			return res.data;
		})
	}
};