import React, { Component } from "react";
import TextFieldDetailsGroup from "../../../common/TextFieldDetailsGroup";

class TutorEmergeContactDetails extends Component {
  render() {
    const { 
      email_ec,
      phone_ec,
      full_name_ec,
      country_ec,
      city_ec,
      street_name_ec,
      house_number_ec,
      postcode_ec,
    } = this.props;

    return (
      <div className="row mx-10">
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Full name"
            value={full_name_ec ? full_name_ec : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Email"
            value={email_ec ? email_ec : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Country"
            value={country_ec ? country_ec.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="City"
            value={city_ec ? city_ec.label : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Street name"
            value={street_name_ec ? street_name_ec : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="House number"
            value={house_number_ec ? house_number_ec : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Postal code"
            value={postcode_ec ? postcode_ec : "-"}
          />
        </div>
        <div className="col-md-6">
          <TextFieldDetailsGroup
            label="Phone"
            value={phone_ec ? phone_ec : "-"}
          />
        </div>
      </div>
    );
  }
}

export default TutorEmergeContactDetails;
