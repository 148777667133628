import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MDSpinner from "react-md-spinner";

/*Import common component*/
import validateInput from "../common/validations/login";
import TextFieldGroupLogin from "../common/TextFieldGroupLogin";
import Notification from "../common/Notification";

/*Import redux*/
import { connect } from "react-redux";
import { userLogin } from "../../actions/authActions";
import { resetTableFilter } from "../../actions/tableFilterActions";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {},
      isLoading: false,
      remember_me: false,
      notification: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true, notification: {} });
      this.props
        .userLogin(this.state)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({
              isLoading: false,
              notification: {
                text: response.message,
                isError: true,
              },
            });
          } else {
            this.setState({ isLoading: false });
            this.props.resetTableFilter();
            this.props.history.push("/dashboard");
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            notification: {
              text: "Internal Server Error, please try again later",
              isError: true,
            },
          });
        });
    } else {
      this.setState({ isLoading: false, notification: {} });
    }
  }
  render() {
    const {
      errors,
      email,
      password,
      // remember_me,
      isLoading,
      notification,
    } = this.state;
    return (
      <div className="card card-round card-shadowed px-50 py-30 w-400px mb-0">
        <img
          className="logo-login"
          src="/assets/img/logo.png"
          alt="logo icon"
        />
        {/* <h5 className="text-center">Flowently CRM</h5> */}
        <form className="form-type-material" onSubmit={this.onSubmit}>
          <div className="form-group">
            <Notification notification={notification} />
          </div>
          <TextFieldGroupLogin
            error={errors.email}
            label="Email/username"
            onChange={this.onChange}
            value={email}
            field="email"
          />
          <TextFieldGroupLogin
            error={errors.password}
            label="Password"
            onChange={this.onChange}
            value={password}
            field="password"
            type="password"
          />
          <div className="form-group">
            <button
              disabled={isLoading}
              className="btn btn-round btn-bold btn-block btn-main-pink"
            >
              {isLoading ? <MDSpinner /> : "Login"}
            </button>
          </div>
          <div className="form-group text-center">
            {/* <div className="custom-control custom-checkbox">
              <input
                value={remember_me}
                type="checkbox"
                name="remember_me"
                className="custom-control-input"
                onChange={this.onChangeCheckbox}
              />
              <label className="custom-control-label">
                <span className='fs-13'>
                  Remember me
                </span>
              </label>
            </div> */}
            <Link
              to="/forget-password"
              disabled={isLoading}
              className="text-muted hover-primary fs-13"
            >
              Forgot password?
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  userLogin: PropTypes.func.isRequired,
};

export default connect(null, { userLogin, resetTableFilter })(LoginForm);
