import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import { connect } from 'react-redux';
import { userGetSingleAppointment } from '../../../actions/appointmentsActions';  
import { isEmpty } from 'lodash';
import TextFieldDetailsGroup from '../../common/TextFieldDetailsGroup';
import LoadingGift from '../../common/LoadingGift';

class AppointmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointment_id: this.props.history.location.state,
      isLoading: false,
      notification: {},
      // Data from DB
      appointment_date: "",
      book_price: 0,
      booking_id: "",
      booking_status: "",
      country: null,
      city: null,
      created_date: "",
      isCanceled: false,
      isConfirmed: false,
      meeting_point: "",
      notes: [],
      person: "0",
      rating: "",
      review: null,
      session_duration: 0,
      session_type: null,
      student_id: null,
      time_session: null,
      tutor_id: null,
    };
  };

  componentWillMount = () => {
    if(isEmpty(this.state.appointment_id)) {
      this.props.history.push('/appointments');
    } else {
      this.getSingleAppointment();
    }
  };

  getSingleAppointment = () => {
    this.setState({ isLoading: true, notification: {}});
    this.props.userGetSingleAppointment({ _id: this.state.appointment_id }).then(res => {
      if(res.status === 200){
        this.setState({ 
          isLoading: false,
          appointment_date : res.message.data.appointment_date,
          book_price : res.message.data.book_price,
          booking_id : res.message.data.booking_id,
          booking_status : res.message.data.booking_status,
          country : res.message.data.country,
          city : res.message.data.city,
          created_date : res.message.data.created_date,
          isCanceled : res.message.data.isCanceled,
          isConfirmed : res.message.data.isConfirmed,
          meeting_point : res.message.data.meeting_point,
          notes : res.message.data.notes,
          person : res.message.data.person,
          rating : res.message.data.rating,
          review : res.message.data.review,
          session_duration : res.message.data.session_duration,
          session_type : res.message.data.session_type,
          student_id : res.message.data.student_id,
          time_session : res.message.data.time_session,
          tutor_id : res.message.data.tutor_id,
        })
      } else {
        this.setState({
          isLoading: false,
          notification: {
            isError: true,
            text: "Some features has been updated, please logout and login again",
          }
        });
      }
    }).catch(err => {
      console.log(err);
      this.setState({
        isLoading: false,
        notification: {
          isError: true,
          text: "Internal server error. Please try again later."
        }
      });
    });
  };
  
  render() {
    const {
      isLoading,
      // notification,
      booking_id,
      booking_status,
      created_date,
      book_price,
      appointment_date,
      // country,
      // city,
      // isCanceled,
      meeting_point,
      // notes,
      // person,
      // rating,
      // review,
      session_duration,
      session_type,
      time_session,
      student_id,
      tutor_id,
    } = this.state;
    const { history } = this.props;

    if(history.location.state) {
      return (
        <div>
          <div className="text-center">
            <h2 className='mb-20'>Appointment details</h2>
          </div>
          { isLoading ? 
            <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "50vh"}}>
              <div className='text-center'>
                <LoadingGift />
                <p>Collecting the appointment data</p>
              </div>
            </div>
            : <div>
              {/* TUTOR AND STUDENT DATA */}
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <div className='card card-body p-30'>
                    <h4 className='text-center mb-0'>Tutor Data</h4>
                    <hr className="my-20"/>
                    <div className='row'>
                      <div className='col-md-4'>
                        <TextFieldDetailsGroup 
                          label='Name' 
                          value={tutor_id ? tutor_id.displayname ? tutor_id.displayname : `${tutor_id.first_name} ${tutor_id.last_name}` : '-'} 
                        />
                      </div>
                      <div className='col-md-4'>
                        <TextFieldDetailsGroup 
                          label='Email' 
                          value={tutor_id ? tutor_id.email : '-'} 
                        />
                      </div>
                      <div className='col-md-4'>
                        <TextFieldDetailsGroup 
                          label='Phone' 
                          value={tutor_id ? tutor_id.phone : '-'} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='card card-body p-30'>
                    <h4 className='text-center mb-0'>Student Data</h4>
                    <hr className="my-20"/>
                    <div className='row'>
                      <div className='col-md-4'>
                        <TextFieldDetailsGroup 
                          label='Name' 
                          value={student_id ? student_id.displayname ? student_id.displayname : `${student_id.first_name} ${student_id.last_name}` : '-'} 
                        />
                      </div>
                      <div className='col-md-4'>
                        <TextFieldDetailsGroup 
                          label='Email' 
                          value={student_id ? student_id.email : '-'} 
                        />
                      </div>
                      <div className='col-md-4'>
                        <TextFieldDetailsGroup 
                          label='Phone' 
                          value={student_id ? student_id.phone : '-'} 
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* BOOKING AND APPOINTMENT DATA */}
              <div className='p-15'>
                <div className='card'>
                  <div className='card-body p-30'>
                    <h4 className='text-center mb-0'>Appointment</h4>
                    <hr className="my-20"/>
                    <div className='row'>
                      <div className='col-md-3'>
                      <TextFieldDetailsGroup 
                        label='Session ID' 
                        value={booking_id ? booking_id : '-'} 
                      />
                      </div>
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Order date' 
                          value={created_date ? moment(created_date, 'MM/DD/YYYY, HH:mm:ss').format('MMM Do, YYYY') : '-'} 
                        />
                      </div>
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Order status' 
                          value={
                            booking_status ? 
                              booking_status === 'open' ? 'New' 
                              : booking_status === 'success' ? 'Completed' 
                              : booking_status === 'cancel' ? 'Cancelled' 
                              : booking_status === 'confirmed' ? 'Confirmed' 
                              : booking_status 
                            : '-'
                          } 
                          addStyle={{
                            color: booking_status === 'open' ? '#0d8fe0' : booking_status === 'success' ? '#288a42' : booking_status === 'confirmed' ? '#15db1f' : booking_status === 'cancel' ? 'red' : 'gray',
                            textTransform: "capitalize"
                          }}
                        />
                      </div>
                      {/* <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Confirmation status' 
                          value={isConfirmed ? `Confirmed` : 'Pending'} 
                          addStyle={{
                            color: isConfirmed ? '#15db1f' : 'red',
                            textTransform: "capitalize"
                          }}
                        />
                      </div> */}
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Session type' 
                          value={session_type ? session_type.label : '-'} 
                        />
                      </div>
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Session duration' 
                          value={session_duration ? Math.floor(session_duration / 60) + 'h ' + session_duration % 60 + 'm' : '-'} 
                        />
                      </div>
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Session date' 
                          value={appointment_date ? moment(appointment_date, 'MM/DD/YYYY').format('MMM Do, YYYY') : '-'} 
                        />
                      </div>
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Credits' 
                          value={book_price ? `${book_price} flowently credits` : '-'} 
                        />
                      </div>
                      <div className='col-md-3'>
                        <TextFieldDetailsGroup 
                          label='Time' 
                          value={time_session ? time_session : 'TBD'}
                          addStyle={{
                            color: time_session ? 'black' : '#c7c7c7'
                          }} 
                        />
                      </div>
                      { session_type && session_type.value === 'live' ? 
                        <div className='col-md-3'>
                          <TextFieldDetailsGroup 
                            label='Meeting Point' 
                            value={meeting_point ? meeting_point : 'TBD'} 
                            addStyle={{
                              color: time_session ? 'black' : '#c7c7c7'
                            }} 
                          />
                        </div>
                      : null }
                      { session_type && session_type.value === 'skype' ? 
                        <div className='col-md-3'>
                          <TextFieldDetailsGroup 
                            label='Tutor skype ID' 
                            value={tutor_id && tutor_id.skype_id ? tutor_id.skype_id : '-'} 
                            addStyle={{
                              color: time_session ? 'black' : '#c7c7c7'
                            }} 
                          />
                        </div>
                      : null }
                    </div>
                  </div>
                  <div className='card-footer px-30 py-20'>
                    <button className='btn btn-main-orange border-round'>Refund</button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      );
    } else {
      return <Redirect to='/appointments' />;
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps, { userGetSingleAppointment })(AppointmentDetails);