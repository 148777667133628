import React from 'react';

function SearchFieldGroup(props) {
  const {
    name,
    value,
    onChange,
    placeholder,
    addedFormClass
  } = props;

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text" style={{borderRadius: "25px 0 0 25px"}} id="basic-addon1">
          <i className="ion-android-search text-black fs-18 font-weight-bold" />
        </span>
      </div>
      <input
        name={name}
        value={value}
        onChange={onChange}
        type="text"
        className={`form-control ${addedFormClass}`}
        placeholder={placeholder}
        aria-describedby="basic-addon1"
      />
    </div>
  );
}

export default SearchFieldGroup;
