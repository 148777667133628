import { SET_DATA } from '../actions/types';

const initialData = {
    data:{}
}

export default (state = initialData, action = {}) => {
	switch(action.type) {
		case SET_DATA:
			return {
				data: action.data
			}			
		default: return state;
	}	
}