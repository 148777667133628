
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Users from '../users';
import Notification from '../../../common/Notification';

import { connect } from 'react-redux';
import { userGetTutorsList } from '../../../../actions/tutorsActions';
import { setTutorsTableFilter } from '../../../../actions/tableFilterActions';

class TutorsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: 1,
      isLoading: false,
      selected: {},
      totalTutors: 0,
      sync: false,
      notification: {},
      errors: {},
    };
    this.table = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getTutorsList(this.table.current.state);
      }
      return true;
    }
    return true;
  }

  // componentWillUnmount() {
  //   this.props.setSalesTableFilter(this.state.table_filter);
  // }

  componentWillMount = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    
    const table_state = {
      sorted: [],
      filtered: [],
      page: 0,
      pageSize: 25,
    };

    this.getTutorsList(table_state, null);
  };

  componentWillUnmount() {
    this.props.setTutorsTableFilter(this.state.table_filter);
  };

  getTutorsList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetTutorsList({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
    }).then((response) => {
      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          loading: false,
          pages: response.message.totalPage,
          totalTutors: response.message.totalTutors,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { match } = this.props;
    const {
      data,
      pages,
      isLoading,
      notification,
      totalTutors,
    } = this.state;

    return (
      <div>
        <Users tab_id="tutors" match={match} />
        <div className="table-card-body mb-30">
          <div className='text-center'>
            <h2 className='mb-2'>All Tutors</h2>
          </div>
          <div className="main-view">
            <p className="text-right fs-16 fw-500 mr-10 py-10">Total tutors : {totalTutors} person</p>
            <Notification notification={notification} />
            <ReactTable
              filterable
              defaultFiltered={this.props.tutorsTableFilter ? this.props.tutorsTableFilter : []}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                // {
                //   Header: "First name",
                //   accessor: "first_name",
                //   sortable: false,
                //   filterMethod: (filter, rows) =>
                //     matchSorter(rows, filter.value, { keys: ["first_name"] }),
                //   filterAll: true,
                //   style: { textAlign: "center" },
                // },
                // {
                //   Header: "Last name",
                //   accessor: "last_name",
                //   sortable: false,
                //   filterMethod: (filter, rows) =>
                //     matchSorter(rows, filter.value, { keys: ["last_name"] }),
                //   filterAll: true,
                //   style: { textAlign: "center" },
                // },
                {
                  Header: "Display Name",
                  accessor: "display_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["display_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Email",
                  accessor: "email",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["email"] }),
                  Cell: (props) => {
                    return <span>{props.row.email}</span>;
                  },
                  style: { 
                    textAlign: "center",
                  },
                },
                {
                  Header: "Hear us from",
                  accessor: "hear_from",
                  Filter: ({ filter, onChange }) => {
                    return (
                      <select
                        className='form-control'
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: "100%", height: "100%" }}
                        value={filter ? filter.value : ""}
                      >
                        <option value="">All</option>
                        <option value="friend">Friend</option>
                        <option value="family">Family</option>
                        <option value="colleague">Colleague</option>
                        <option value="social_media">Social media</option>
                        <option value="newsletter">Newsletter</option>
                        <option value="google_search">Google search</option>
                        <option value="website">Website</option>
                        <option value="-">Not define</option>
                      </select>
                    )
                  },
                  Cell: (row) => {
                    let value_label = '-';
                    
                    if(row.value) {
                      value_label = row.value.split('_').join(' ').toLowerCase();
                      value_label = value_label.charAt(0).toUpperCase()+value_label.slice(1);
                    };

                    return (
                      <span>
                        <span className="">
                          { value_label }
                        </span>
                      </span>
                    )
                  },
                  style: { textAlign: "center" },
                },
                {
                  Header: "Approvement status",
                  accessor: "is_approved",
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%", height: '100%' }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">All</option>
                      <option value={true}>Approved</option>
                      <option value={false}>Not approved</option>
                    </select>,
                  Cell: row => (
                    <span
                      style={{
                        color: row.value ? '#57d500' : '#ff2e00'
                      }}
                    >
                      {
                        row.value === true ? 'Approved' 
                        : row.value === false ? 'Not Approved'
                        : null 
                      }
                    </span>
                  ),
                  style:{"textAlign":"center"}
                },
                {
                  Header: "Account status",
                  accessor: "is_active",
                  Filter: ({ filter, onChange }) =>
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%", height: '100%' }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">All</option>
                      <option value={true}>Active</option>
                      <option value={false}>Not active</option>
                    </select>,
                  Cell: row => (
                    <span>
                      <span
                        style={{
                          color: row.value === true ? "#57d500" : "#ff2e00",
                          transition: "all .3s ease",
                          marginRight: 10
                        }}
                      >
                        &#x25cf;
                      </span>
                      {row.value === true ? "Active" : "Not active"}
                    </span>
                  ),
                  style:{"textAlign":"center"}
                },
                {
                  Header: "Appointment history",
                  accessor: "_id",
                  sortable: false,
                  filterable: false,
                  Cell: (props) => {
                    return (
                      <span>
                        <Link
                          to={{
                            pathname: `${match.path}/history`,
                            state: {
                              tutor_id: props.value,
                              tutor_name: `${props.row._original.first_name} ${props.row._original.last_name}`,
                            },
                          }}
                        >
                          <button className="btn-round btn-main-orange border-0 cursor-pointer px-3 py-1">
                            See history
                          </button>
                        </Link>
                      </span>
                    )
                  },
                  style: { textAlign: "center" },
                },
                {
                  Header: "",
                  accessor: "_id",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <span>
                      <Link
                        to={{
                          pathname: `${match.path}/details`,
                          state: row.value,
                        }}
                      >
                        <button className="btn-round btn-main-orange border-0 cursor-pointer px-3 py-1">
                          details
                        </button>
                      </Link>
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
              ]}
              ref={this.table}
              data={data} // should default to []
              pages={pages} // should default to -1 (which means we don't know how many pages we have)
              loading={isLoading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={this.getTutorsList}
              defaultPageSize={25}
              showPagination={true}
              className="-striped -highlight"
            />
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    modal: state.modal,
    tutorsTableFilter: state.tableFilter.tutors,
  }
}

export default connect(mapStateToProps, { userGetTutorsList, setTutorsTableFilter })(TutorsTable);
