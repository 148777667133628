import React from "react";

import TopHeader from "./parts/topHeader";
import LeftPane from "./parts/leftPane";
import Footer from "./parts/footer";
import Breadcrumb from "./parts/breadcrumb";

class Layout extends React.Component {
  render() {
    const { match, history } = this.props;
    return (
      <div id="wrapper">
        <LeftPane history={history} />
        <TopHeader history={history} />
        <main className="main-background">
          <div className="main-content mt-0 px-60">
            <Breadcrumb history={history} match={match} />
          </div>
          <div className="px-30">{this.props.children}</div>
          <Footer />
        </main>
      </div>
    );
  }
}

export default Layout;
