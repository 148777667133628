import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';
import { isEmpty } from 'lodash';

import Notification from '../../../common/Notification';
import TextFieldGroupLogin from '../../../common/TextFieldGroupLogin';

import { connect } from 'react-redux';
import { userEditLanguage } from '../../../../actions/commonActions';

class EditLanguageModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: {},
			errors: {},
			connect_id: props?.selectedLang?.connect_id || 0,
            label: props?.selectedLang?.label || '',
            value: props?.selectedLang?.value || '',
            _id: props?.selectedLang?._id || '',
		};
	}

	hasNumber = (text) => {
		return /\d/.test(text);
	};

	validateInput = () => {
		let errors = {};

        if (!this.hasNumber(this.state.connect_id)) {
            errors.connect_id = 'Please insert only numeric';
        }

		if (isEmpty(this.state.label)) {
			errors.label = 'Label is required';
		}

		if (isEmpty(this.state.value)) {
			errors.value = 'Value is required';
		}

		if (isEmpty(this.state._id)) {
			errors._id = 'Unique ID is required';
		}

		return {
			errors,
			isValid: isEmpty(errors),
		};
	};

	isValid = () => {
		const { errors, isValid } = this.validateInput();
		if (!isValid) {
			this.setState({ errors });
		}
		return isValid;
	};

	onSubmit = (e) => {
		e.preventDefault();
		this.setState({ isLoading: true, notification: {}, errors: {} });
		if (this.isValid()) {
            const { connect_id, label, value, _id } = this.state;

			const dataToSubmit = {
                connect_id,
				value: value.split(' ').join('-').toLocaleLowerCase(),
				label,
                _id
			};
            
            console.log('dataToSubmit', dataToSubmit);

			this.props.userEditLanguage(dataToSubmit).then((res) => {
                console.log('res', res);
				if (res.status === 200) {
					this.setState({
						isLoading: false,
						notification: {
							isError: false,
							text: res.message,
						},
					});
					this.props.getLanguageList();
					setTimeout(() => {
						this.props.closeModal();
					}, 1500);
				} else {
					this.setState({
						isLoading: false,
						notification: {
							text: res.message,
							isError: true,
						},
					});
				}
			});
		} else {
			this.setState({
				isLoading: false,
				notification: {
					text: 'Please complete the form',
					isError: true,
				},
			});
		}
	};

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			errors: {
                ...this.state.errors,
				[e.target.name]: null,
			},
			notification: {},
		});
	};

	render() {
		const { selectedLang, closeModal } = this.props;
		const { isLoading, notification, errors } = this.state;
		return (
			<div>
				<div
					className='modal modal-center fade show'
					id='modal-center'
					style={{ display: 'block' }}>
					<div className='modal-dialog'>
						<div className='modal-content'>
							<div className='text-right px-30 pt-20'>
								<i
									onClick={closeModal}
									className='ion-close-round cursor-pointer'></i>
							</div>
							<div className='modal-body m-0 p-30'>
								<h5 className='text-center fs-26 font-weight-bold mb-4'>
									Edit {selectedLang?.label} Language
								</h5>
								{notification.text && (
									<Notification notification={notification} />
								)}
								<form
									className=''
									onSubmit={this.onSubmit}>
									<TextFieldGroupLogin
                                        type='number'
										error={errors?.connect_id || ''}
										label='Connect ID'
										onChange={this.onChange}
										defaultValue={selectedLang?.connect_id}
										field='connect_id'
									/>
									<TextFieldGroupLogin
										error={errors?.label || ''}
										label='Label'
										onChange={this.onChange}
										defaultValue={selectedLang?.label}
										field='label'
									/>
									<TextFieldGroupLogin
										error={errors?.value || ''}
										label='Value'
										onChange={this.onChange}
										defaultValue={selectedLang?.value}
										field='value'
									/>
									<input
										type='hidden'
										name='_id'
										onChange={this.onChange}
										value={selectedLang?._id}
									/>
									<div className='form-group mt-10'>
										<button
											disabled={isLoading}
											className='btn btn-block p-10 btn-main-orange fs-16 fw-500'
											style={{
												borderRadius: 25,
											}}>
											{isLoading ? <MDSpinner /> : 'Edit'}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className='modal-backdrop fade show'></div>
			</div>
		);
	}
}

export default connect(null, { userEditLanguage })(EditLanguageModal);
