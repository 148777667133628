import { SET_CURRENT_USER, UPDATE_USER } from '../actions/types';
import isEmpty from 'lodash/isEmpty';

const initialState = [{
  isAuthenticated: false,
  user: {}
}]

export default (state = initialState, action = {}) => {
  switch(action.type) {
    case SET_CURRENT_USER:
      return {
          isAuthenticated: !isEmpty(action.user),
          user: action.user
      };
    case UPDATE_USER:
      const { save_tanggal_lahir, tempat_lahir, nomor_telepon, email_pribadi } = action.user;
      const newState = Object.assign({},state);
      newState.user.tanggal_lahir = save_tanggal_lahir;
      newState.user.tempat_lahir = tempat_lahir;
      newState.user.nomor_telepon = nomor_telepon;
      newState.user.email_pribadi = email_pribadi;
      return newState;
    default: return state;
  } 
}