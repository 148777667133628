import React from 'react'

function SwitchButton({value, onChange}) {
    return (
        <div>
            <label className="switch m5">
                <input type="checkbox" checked={value} onChange={onChange}/>
                <small></small>
            </label>
        </div>
    )
}

export default SwitchButton
