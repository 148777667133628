import { API } from '../utils/apiConnect';

export function userGetPackageOrderOverview(data) {
    return dispatch => {
		return API.post('/dashboard/order-overview', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetBookingOverview(data) {
    return dispatch => {
		return API.post('/dashboard/booking-overview', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetTutorOverview(data) {
    return dispatch => {
		return API.post('/dashboard/tutor-overview', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetStudentOverview(data) {
    return dispatch => {
		return API.post('/dashboard/student-overview', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetTutorSignupSource(data) {
    return dispatch => {
		return API.get('/dashboard/tutor-signup-source', data )
		.then( res => {
			return res.data;
		})
	}
}

export function userGetStudentSignupSource(data) {
    return dispatch => {
		return API.get('/dashboard/student-signup-source', data )
		.then( res => {
			return res.data;
		})
	}
}