import React from "react";

import Notification from "../../common/Notification";
import LoadingGift from "../../common/LoadingGift";
import EditVoucherModal from "./editVoucherModal";

/*Import redux*/
import { connect } from "react-redux";
import { userGetAllVouchers } from "../../../actions/voucherActions";
import AddVoucherModal from "./addVoucherModal";

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      isLoading: false,
      selected: {},
      totalVouchers: 0,
      sync: false,
      notification: {},
      errors: {},
      isEditModalOpen: false,
      isAddModalOpen: false,
      selectedVoucher: {},
    };
    this.table = React.createRef();
  }
  
  componentWillMount = () => {
    this.getVoucherList();
  };

  getVoucherList = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetAllVouchers().then((response) => {
      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          totalVouchers: response.message.data.length,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  openEditVoucherHandler = (data) => {
    this.setState({ 
      isEditModalOpen : true,
      selectedVoucher: data
    });
  };

  closeEditVoucherHandler = () => {
    this.setState({
      isEditModalOpen: false,
      selectedVoucher: {},
    });
    this.getVoucherList();
  };

  openAddVoucherHandler = () => {
    this.setState({ 
      isAddModalOpen : true
    });
  };
  
  closeAddVoucherHandler = () => {
    this.setState({
      isAddModalOpen: false,
    });
    this.getVoucherList();
  }

  renderVoucherBenefits = (benefits, sidx) => {
    
    const result = benefits.map((data, idx) => {
      return (
        <div key={`benefit-${sidx+1}-${idx+1}`} className='d-flex align-items-start px-60 my-10'>
          <i className='fa fa-check-circle-o fs-19' style={{color: '#2acf7f'}}></i>
          <span className={data.bold_text ? 'ml-3 font-weight-bold' : 'ml-3'}>{data.text}</span>
        </div>
      );
    });

    return result;
  };

  render() {
    // const { match } = this.props;
    const {
      data,
      isLoading,
      notification,
      selectedVoucher,
      // totalVouchers,
      isEditModalOpen,
      isAddModalOpen
    } = this.state;

    const render_vouchers = data.map((item, idx) => {
      return (
        <div key={`voucher-${idx+1}`} className='col-md-4 voucher-card'>
          <div className='card card-body p-20'>
            {/* HEADER */}
            <div className="d-flex justify-content-between">
              <div className='px-20'>
                <h3 className='text-uppercase text-left mb-0'>{item.title}</h3>
                <p className='text-left mb-0'>Gift card ID : {item.voucher_id}</p>
              </div>
              <div>
                <p 
                  className={`mb-10 py-1 text-white text-center ${item.is_active ? "px-20" : ""}`}
                  style={{
                    borderRadius: 20,
                    backgroundColor: item.is_active ? '#15db1f' : 'red'
                  }}
                >
                  {item.is_active ? 'Active' : 'Non-active'}
                </p>
                <button onClick={() => this.openEditVoucherHandler(item)} className='btn btn-main-orange btn-round'>
                  <i className='fa fa-edit mr-2'/>
                  Edit
                </button>
              </div>
            </div>
            <hr className='my-20' />
            {/* BODY */}
            <div className='text-center'>
              <h3 className='text-uppercase fs-60 mb-0'>{item.credit_value}</h3>
              <h3 className='mb-0'>hours</h3>
              <p className='mt-15 mb-0 mx-auto' style={{maxWidth: 360}}>{item.description}</p>
              <div className='my-20'>
                { this.renderVoucherBenefits(item.benefit_list, idx) }
              </div>
              <div className='price-box mx-auto my-10 py-20'>
                <h5 className='mb-0 fs-24 lh-1 text-center mb-2' style={{textDecoration: 'line-through', color: 'rgba(0, 0, 0, 0.6)'}}>
                  &euro;
                  {item.origin_price}
                </h5>
                <h5 className='mb-0 fs-24 lh-1 text-center mb-2' style={{color: '#000000'}}>
                  &euro;
                  {item.sale_price}
                </h5>
              </div>
            </div>
          </div>
        </div>
      )
    });

    return (
      <div>
        <div className='card mx-15'>
          <div className='card-header flex-column justify-content-center'>
            <h1 className='mb-0'>
              Gift cards
            </h1>
            <div 
              role='button' 
              id='muted-text-group' 
              style={{right: 34}}
              onClick={() => this.openAddVoucherHandler()} 
              className='position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-info btn-round py-10 px-20' 
            >
              <i className='ion-plus-round cursor-pointer mr-2'></i>
              Add card
            </div>
          </div>
        </div>
        <div className='mx-15'>
          <Notification notification={notification} />
        </div>
        { isLoading ?
          <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "50vh"}}>
            <div className='text-center'>
              <LoadingGift />
              <p>Collecting gift cards data</p>
            </div>
          </div>
          : <div className='row mx-auto'>
            { render_vouchers }
          </div>
        }
        { isEditModalOpen ? 
          <EditVoucherModal 
            voucher_id={selectedVoucher._id}
            title={selectedVoucher.title}
            description={selectedVoucher.description}
            credit_value={selectedVoucher.credit_value}
            is_active={selectedVoucher.is_active}
            origin_price={selectedVoucher.origin_price}
            sale_price={selectedVoucher.sale_price}
            benefit_list={selectedVoucher.benefit_list}
            onCloseModal={this.closeEditVoucherHandler}
          /> 
        : null }
        { isAddModalOpen ? 
          <AddVoucherModal 
            onCloseModal={this.closeAddVoucherHandler}
          /> 
        : null }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    modal: state.modal
  };
}

export default connect(mapStateToProps, {userGetAllVouchers})(Vouchers);
