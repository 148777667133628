import React from 'react';
import Layout from '../layout/Layout';
import Vouchers from './views/vouchers';

class VoucherPage extends React.Component {
	render() {
		const { history, match } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
                        <Vouchers history={history} match={match} />
                	</div>
                </main>
             </Layout>
		);
	}
}

export default VoucherPage;
