import React from 'react'

const DownloadErrorModal = ({ closeModal }) => {
  return (
    <div>
      <div
        className="modal modal-center fade show"
        id="modal-center"
        style={{ display: "block" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className='modal-body text-center m-0 p-30'>
              <img className='h-80px mb-20' src="/assets/img/icon/error.svg" alt='error' />
              <h5 className="fs-26 font-weight-bold mb-0">
                Failed to downlaod
              </h5>
              <p className='px-40 fs-14'>Package bought is not exist anymore.</p>
              <button onClick={closeModal} className='btn-round btn-main-orange border-0 cursor-pointer px-3 py-1'>OK</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default DownloadErrorModal
