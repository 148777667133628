import React from 'react';
import ReactInputMask from 'react-input-mask';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

const  DateSelectGroup = ({
  minDate,
  maxDate,
  value,
  label,
  error,
  onChange,
  placeholderText,
  type,
  name,
  isDisabled,
  dateAvailableArray
}) => {
  
  let dateFilter = null;

  const isWeekday = date => {
    const day = new Date(date).getDay();
    return day !== 0 && day !== 6;
  };

  const isWeekend = date => {
    const day = new Date(date).getDay();
    return day === 0 || day === 6;
  };

  if(dateAvailableArray) {
    if(dateAvailableArray.includes('weekend') && dateAvailableArray.includes('weekdays')) {
      dateFilter = null
    } 
    if(dateAvailableArray.includes('weekend') && !dateAvailableArray.includes('weekdays')) {
      dateFilter = isWeekend
    } 
    if(!dateAvailableArray.includes('weekend') && dateAvailableArray.includes('weekdays')) {
      dateFilter = isWeekday
    } 
  }


  return (
    <div className={classnames(!label ? 'form-group mb-0' : 'form-group', { 'has-error has-danger': error })}>
      { label ?
        <label className="col-form-label font-weight-bold fs-15 pl-12">
          {label}
          <span className='fs-12 pl-2 font-italic text-gray'>{type === 'input-mask' ? '( MM-DD-YYYY )' : null}</span>
        </label>
      : null }
      <div className="input-group">
        <div className="flex-grow-2">
          { type === 'input-mask' ? 
            <ReactInputMask 
              value= {value}
              mask="99-99-9999"
              name={name}
              className={error ? 'form-control react-datepicker-style border-danger px-20' : 'form-control react-datepicker-style px-20'}
              onChange= {onChange}
              disabled={isDisabled}
              placeholder='MM-DD-YYYY'
            />
            : <DatePicker
                selected={value}
                dateFormat="dd-MM-yyyy"
                onChange={onChange}
                className={
                  error ? 'form-control react-datepicker-style px-20 border-danger'
                  : 'form-control react-datepicker-style px-20'
                }
                filterDate={dateFilter}
                placeholderText="DD-MM-YYYY"
                disabled={isDisabled}
                minDate={minDate ? minDate : null}
                maxDate={maxDate ? maxDate : null}
                fixedHeight={true}
                scrollableYearDropdown={false}
                showYearDropdown={false}
                showMonthDropdown={true}
                popperPlacement="bottom-start"
              />
          }
        </div>
        <i 
          className="fa fa-calendar-o absolute-center"
          style={{
            position: 'absolute',
            top: '28%',
            right: 20
          }}
        ></i>
        {/* <div className="input-group-prepend">
          <span className="input-group-text p-10 bg-transparent">
          </span>
        </div> */}
      </div>
      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  )
}

export default DateSelectGroup
