import { SET_TOKEN } from '../actions/types';

const initialToken = {
    token:[]
}

export default (state = initialToken, action = {}) => {
	switch(action.type) {
		case SET_TOKEN:
			return {
				token: action.token
			}			
		default: return state;
	}	
}