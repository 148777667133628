import { API } from '../utils/apiConnect';

export function userGetStudentsList(data) {
	return dispatch => {
		return API.post('/student-crm/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetSingleStudent(data) {
	return dispatch => {
		return API.post('/student-crm/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetStudentAppointmentsHistory(data) {
	return dispatch => {
		return API.post('/student-crm/appointments-history', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetStudentCreditHistory(data) {
	return dispatch => {
		return API.post('/student-crm/credit-history', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetStudentPackageOrdersHistory(data) {
	return dispatch => {
		return API.post('/student-crm/package-orders', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetStudentVoucherOrdersHistory(data) {
	return dispatch => {
		return API.post('/student-crm/voucher-orders', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userEditStudent(data) {
	return dispatch => {
		return API.post('/student-crm/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userDeleteStudent(data) {
	return dispatch => {
		return API.post('/student-crm/delete', data)
		.then( res => {
			return res.data;
		});
	}
}