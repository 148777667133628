import React from 'react';
import AccountActivation from './accountActivation';

class accountActivationPage extends React.Component {
	render() {
		const { history } = this.props;
		return(
			<div className="min-h-fullscreen center-vh p-20 pace-done">
				<AccountActivation history={history}/>
			</div>
		);
	}
}

export default accountActivationPage;


