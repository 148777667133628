import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tab: this.props.tab_id,
    };
  };
  
  onChangeActiveOverview = value => {
    this.setState({ active_tab : value });
  };
  
  render() {
    const { 
      active_tab
    } = this.state;

    return (
      <div id='users-overview'>
        <div className='card card-body p-0'>
          <div className="text-center">
            <h2 className='my-20'>Users overview</h2>
          </div>
          <ul className="nav nav-tabs nav-justified my-40">
            <li className="nav-item mx-2">
              <Link 
                to={`/users/students`}
                onClick={() => this.onChangeActiveOverview('students')}
                className={`nav-link fs-16 ${active_tab === 'students' ? 'active' : '' }`}
              >
                Students
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link 
                to={`/users/tutors`}
                onClick={() => this.onChangeActiveOverview('tutors')}
                className={`nav-link fs-16 ${active_tab === 'tutors' ? 'active' : '' }`}
              >
                Tutors
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };
};

export default Users;
