import React, { Component } from "react";
import { isEmpty } from "lodash";
import MDSpinner from "react-md-spinner";
import moment from 'moment';

import SelectFieldGroup from "../../../common/SelectFieldGroup";
import TextFieldGroupLogin from "../../../common/TextFieldGroupLogin";
import AsyncSelectFieldGroup from "../../../common/AsyncSelectFieldGroup";
import MultiSelectFieldGroup from "../../../common/MultiSelectFieldGroup";

import { language_options, timezone_options } from '../../../common/SelectOptionsData';
import { validateStudentProfile } from "../../../common/validations/studentEdit";

import { connect } from 'react-redux';
import { userGetAllCountries } from '../../../../actions/commonActions';
import { userEditStudent } from '../../../../actions/studentsActions';
import Notification from "../../../common/Notification";

class StudentEditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {},
      city_options: [],
      living_in_city_options: [],
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      living_in_country: this.props.living_in_country,
      living_in_city: this.props.living_in_city,
      timezone: this.props.timezone,
      student_id: this.props.student_id,
      date_of_birth: this.props.date_of_birth,
      gender: this.props.gender,
      from_country: this.props.from_country,
      from_city: this.props.from_city,
      phone: this.props.phone,
      bridge_language: this.props.bridge_language,
      learn_language: this.props.learn_language,
      skype_id: this.props.skype_id,
    };
  };

  componentWillMount = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });

    // From city options
    if(this.props.from_country && !isEmpty(this.props.from_country)) {
      const queryInputCountry = this.props.from_country.label.toLowerCase();
      this.props.userGetAllCountries({query:queryInputCountry})
      .then((response) => {
        let city_options = response.message.data[0].city_options;
        this.setState({ 
          isLoading: false,
          city_options,
        });
      }).catch((error) => {
        console.log(error);
        this.setState({isLoading:false });
      });
    };

    if(this.props.living_in_country && !isEmpty(this.props.living_in_country)) {
      const queryInputCountry = this.props.living_in_country.label.toLowerCase();
      this.props.userGetAllCountries({query:queryInputCountry})
      .then((response) => {
        let living_in_city_options = response.message.data[0].city_options;
        this.setState({ 
          isLoading: false,
          living_in_city_options,
        });
      }).catch((error) => {
        console.log(error);
        this.setState({isLoading:false });
      });
    };
    
    this.setState({ isLoading: false });
  };

  isValid = () => {
    const { errors, isValid } = validateStudentProfile(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitEditProfile = (e) => {
    e.preventDefault();
    const { 
      student_id,
      first_name,
      last_name,
      living_in_country,
      living_in_city,
      timezone,
      date_of_birth,
      from_country,
      from_city,
      phone,
      bridge_language,
      learn_language,
    } = this.state;

    this.setState({ isLoading: true, notification: {}, errors: {} });

    let learn_language_save = []; 
    
    if(!isEmpty(learn_language)) {
      learn_language_save = learn_language.map(item => {
        return {
          language: item,
          language_level: null,
          error: null,
        };
      });
    };
    
    let bridge_language_save = [];
    if(!isEmpty(bridge_language)) {
      bridge_language_save = bridge_language.map(item => {
        return {
          language: item,
          language_level: null,
          error: null,
        };
      });
    };

    const dataToSubmit = {
      _id: student_id,
      data: {
        first_name,
        last_name,
        living_in_country,
        living_in_city,
        timezone,
        student_id,
        date_of_birth: isEmpty(date_of_birth) || date_of_birth.toLowerCase() === 'invalid date' ? '' : moment(date_of_birth, 'MM-DD-YYYY').format("MM/DD/YYYY"),
        from_country,
        from_city,
        phone,
        bridge_language: bridge_language_save,
        learn_language: learn_language_save
      }
    };

    if(this.isValid()) {
      this.props.userEditStudent(dataToSubmit).then(res => {
        if(res.status === 200) {
          this.setState({ 
            isLoading: false,
          });
          this.props.closeEditing();
        } else {
          this.setState({
            isLoading: false,
            notification: {
              text: 'We are experiencing technical difficulties at the moment, please try again later',
              isError: true
            }
          });
        };
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          text: 'Please complete the form above',
          isError: true
        }
      });
    };
  };
  
  loadOptionsCountry = (inputValue, callback) => {
    this.props.userGetAllCountries({query:inputValue})
    .then((response) => {
      callback(response.message.data);
    }).catch((error) => {
      this.setState({isLoading:false });
    });
  };

  onChangeSelectCountry = (val) => {
    const from_country = {
      value: val.value,
      label: val.label
    };

    const city_options = val.city_options;

    this.setState({ from_country, city_options, from_city: null });
  };

  onChangeSelectCountryLive = (val) => {
    const country = {
      value: val.value,
      label: val.label
    };

    const city_options = val.city_options;

    this.setState({ living_in_country : country, living_in_city_options: city_options, living_in_city: null });
  };

  onChangeSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  onChangeInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };


  render() {
    const { 
      isLoading,
      notification,
      city_options,
      living_in_city_options,
      errors,
      first_name,
      last_name,
      living_in_country,
      living_in_city,
      timezone,
      from_country,
      from_city,
      phone,
      bridge_language,
      learn_language,
    } = this.state;

    return (
      <div>
        <div className="row m-auto">
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="First Name"
              field="first_name"
              value={first_name}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.first_name}
            />
          </div>
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Last Name"
              field="last_name"
              value={last_name}
              placeholder="Type something"
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.last_name}
            />
          </div>
          <div className="col-md-6">
            <AsyncSelectFieldGroup
              label="From"
              labelClass="font-weight-normal"
              placeholder="Type to search"
              value={from_country}
              loadOptions={this.loadOptionsCountry}
              onChange={this.onChangeSelectCountry}
              error={errors.from_country}
              isDisabled={isLoading}
              isClearable={false}
            />
          </div>
          <div className="col-md-6">
            <SelectFieldGroup
              label="City"
              labelClass="text-transparent"
              value={from_city}
              options={city_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "from_city", value: val },
                })
              }
              error={errors.from_city}
              isClearable={false}
              isDisabled={isLoading || isEmpty(from_country)}
              isSearchable={true}
            />
          </div>
          <div className="col-md-6">
            <AsyncSelectFieldGroup
              label="Living in"
              placeholder="Type to search"
              value={living_in_country}
              loadOptions={this.loadOptionsCountry}
              onChange={this.onChangeSelectCountryLive}
              error={errors.living_in_country}
              isDisabled={isLoading}
              isClearable={false}
            />
          </div>
          <div className="col-md-6">
            <SelectFieldGroup
              label="City"
              labelClass="text-transparent"
              value={living_in_city}
              options={living_in_city_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "living_in_city", value: val },
                })
              }
              error={errors.living_in_city}
              isClearable={false}
              isDisabled={isLoading || isEmpty(living_in_country)}
              isSearchable={true}
            />
          </div>
          <div className="col-md-12">
            <MultiSelectFieldGroup
              label="Learn Language"
              value={learn_language}
              options={language_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "learn_language", value: val },
                })
              }
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={true}
              error={errors.learn_language}
            />
          </div>
          <div className="col-md-12">
            <MultiSelectFieldGroup
              label="Bridge Language"
              value={bridge_language}
              options={language_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "bridge_language", value: val },
                })
              }
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={true}
              error={errors.bridge_language}
            />
          </div>
          <div className="col-md-6">
            <SelectFieldGroup
              label="Time zone"
              value={timezone}
              options={timezone_options}
              onChange={(val) =>
                this.onChangeSelect({
                  target: { name: "timezone", value: val },
                })
              }
              isClearable={false}
              isDisabled={isLoading}
              isSearchable={true}
              error={errors.timezone}
            />
          </div>
          {/* <div className="col-md-6">
            <DateSelectGroup
              value={date_of_birth}
              label="Birthday"
              type="input-mask"
              name="date_of_birth"
              onChange={this.onChangeInput}
              isDisabled={isLoading}
              error={errors.date_of_birth}
            />
          </div> */}
          <div className="col-md-6">
            <TextFieldGroupLogin
              label="Phone"
              placeholder="Your phone number"
              field="phone"
              value={phone}
              onChange={this.onChangeInput}
              disabled={isLoading}
              error={errors.phone}
            />
          </div>
        </div>
        <Notification notification={notification} />
        <hr className='my-20' />
        { isLoading ? 
          <div className='d-flex align-items-center justify-content-end'>
            <MDSpinner />
          </div>
          : <div className='d-flex align-items-center justify-content-end'>
            <button onClick={this.onSubmitEditProfile} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save changes</button>
            <button onClick={() => this.props.closeEditing()} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
          </div>
        }
      </div>
    );
  }
}

export default connect(null, { userEditStudent, userGetAllCountries })(StudentEditProfile);
