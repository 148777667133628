import React from 'react';
import LoginForm from './loginForm';


class loginPage extends React.Component {
	render() {
		const { history } = this.props;
		return(
			<div className="min-h-fullscreen center-vh p-20 pace-done">
				<LoginForm history={history}/>
			</div>
		);
	}
}


export default loginPage;


