import React, { Component } from "react";
import PropTypes from "prop-types";
import QS from "query-string";
import MDSpinner from "react-md-spinner";

/*Import common component*/
import validateInput from "../common/validations/resetPassword";
import TextFieldGroupLogin from "../common/TextFieldGroupLogin";
import Notification from "../common/Notification";

/*Import redux*/
import { connect } from "react-redux";
import { userResetPassword } from "../../actions/authActions";

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      token: "",
      repeat_new_password: "",
      new_password: "",
      errors: {},
      isLoading: false,
      notification: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount(props) {
    let queries = QS.parse(this.props.history.location.search);
    if (queries && queries.token) this.setState({ token: queries.token });
    if (queries && queries.email) this.setState({ email: queries.email });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true });
      this.props
        .userResetPassword({
          email: this.state.email,
          token: this.state.token,
          new_password: this.state.new_password,
        })
        .then((response) => {
          if (response.status !== 200) {
            this.setState({
              isLoading: false,
              email: "",
              notification: {
                text: response.message,
                isError: true,
              },
            });
          } else {
            this.setState({
              isLoading: true,
              email: "",
              notification: {
                text: response.message,
                isError: false,
              },
            });
            setTimeout(() => {
              this.setState({
                isLoading: false,
              });
              this.props.history.push("/login");
            }, 5000);
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            notification: {
              text: "Internal Server Error, please try again later",
              isError: true,
            },
          });
        });
    }
  }

  render() {
    const {
      errors,
      email,
      repeat_new_password,
      new_password,
      isLoading,
      notification,
    } = this.state;
    return (
      <div className="card card-round card-shadowed px-50 py-30 w-400px mb-0">
        <img
          className="logo-login"
          src="/assets/img/logo.png"
          alt="logo icon"
        />
        <h5 className="text-center">Flowently CRM</h5>
        <div className="form-group">
          <Notification notification={notification} />
        </div>
        <form className="form-type-material" onSubmit={this.onSubmit}>
          <TextFieldGroupLogin
            error={errors.email}
            label="Email"
            onChange={this.onChange}
            value={email}
            field="identifier"
            disabled="true"
          />
          <TextFieldGroupLogin
            error={errors.new_password}
            label="New Password"
            onChange={this.onChange}
            value={new_password}
            field="new_password"
            type="password"
          />
          <TextFieldGroupLogin
            error={errors.repeat_new_password}
            label="Repeat New Password"
            onChange={this.onChange}
            value={repeat_new_password}
            field="repeat_new_password"
            type="password"
          />
          <div className="form-group">
            <button
              disabled={isLoading}
              className="btn btn-bold btn-round btn-block btn-main-pink"
            >
              {isLoading ? <MDSpinner /> : <span>Reset Password</span>}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

resetPassword.propTypes = {
  userResetPassword: PropTypes.func.isRequired,
};

export default connect(null, { userResetPassword })(resetPassword);
