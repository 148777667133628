import React from "react";

import Notification from "../../common/Notification";
import LoadingGift from "../../common/LoadingGift";
import AddSessionPackageModal from "./addSessionPackageModal";
import EditSessionPackageModal from "./editSessionPackageModal";

/*Import redux*/
import { connect } from "react-redux";
import { userGetAllPackages } from "../../../actions/packageActions";

class SessionPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      isLoading: false,
      selected: {},
      totalPackages: 0,
      sync: false,
      notification: {},
      errors: {},
      isEditModalOpen: false,
      isAddModalOpen: false,
      selectedPackage: {},
    };
    this.table = React.createRef();
  }
  
  componentWillMount = () => {
    this.getPackageList();
  };

  getPackageList = () => {
    this.setState({ isLoading: true, notification: {}, errors: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetAllPackages().then((response) => {
      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        let data = [];

        response.message.data.map(item => {
          if(item.single_session) {
            data.push(item); 
          };

          return item;
        });

        data = data.sort((a, b) => a.person - b.person);

        this.setState({
          data,
          isLoading: false,
          totalPackages: response.message.data.length,
        });
      }
    }).catch((error) => {
      console.log(error)
      this.setState({ isLoading: false });
    });
  };

  openEditPackageHandler = (data) => {
    this.setState({ 
      isEditModalOpen : true,
      selectedPackage: data
    });
  };

  closeEditPackageHandler = () => {
    this.setState({
      isEditModalOpen: false,
      selectedPackage: {},
    });
    this.getPackageList();
  };

  openAddPackageHandler = () => {
    this.setState({ 
      isAddModalOpen : true
    });
  };
  
  closeAddPackageHandler = () => {
    this.setState({
      isAddModalOpen: false,
    });
    this.getPackageList();
  }

  renderPackageBenefits = (benefits, sidx) => {
    
    const result = benefits.map((data, idx) => {
      return (
        <div key={`benefit-${sidx+1}-${idx+1}`} className='d-flex align-items-start px-60 my-10'>
          <i className='fa fa-check-circle-o fs-19' style={{color: '#2acf7f'}}></i>
          <span className={data.bold_text ? 'ml-3 font-weight-bold' : 'ml-3'}>{data.text}</span>
        </div>
      );
    });

    return result;
  };

  render() {
    // const { match } = this.props;
    const {
      data,
      isLoading,
      notification,
      selectedPackage,
      // totalPackages,
      isEditModalOpen,
      isAddModalOpen
    } = this.state;

    const render_packages = data.map((item, idx) => {
      return (
        <div key={`package-${idx+1}`} className='col-md-4 package-card'>
          <div className='card card-body p-20'>
            {/* HEADER */}
            <div className="d-flex justify-content-between">
              <div className='px-20'>
                <h3 className='text-uppercase text-left mb-0'>{item.title}</h3>
                <p className='text-left mb-0'>Package ID : {item.package_id}</p>
              </div>
              <div>
                <p 
                  className={`mb-10 py-1 text-white text-center ${item.is_active ? "px-20" : ""}`}
                  style={{
                    borderRadius: 20,
                    backgroundColor: item.is_active ? '#15db1f' : 'red'
                  }}
                >
                  {item.is_active ? 'Active' : 'Non-active'}
                </p>
                <button onClick={() => this.openEditPackageHandler(item)} className='btn btn-main-orange btn-round'>
                  <i className='fa fa-edit mr-2'/>
                  Edit
                </button>
              </div>
            </div>
            <hr className='my-20' />
            {/* BODY */}
            <div className='text-center'>
              <h3 className='text-uppercase fs-60 mb-0'>{item.hours}</h3>
              <h3 className='mb-0'>hours</h3>
              <p className='mt-15 mb-0 mx-auto' style={{maxWidth: 360}}>{item.description}</p>
              <div className='price-box mx-auto my-10 py-20'>
                <h5 className='mb-0 fs-24 lh-1 text-center mb-2' style={{textDecoration: 'line-through', color: 'rgba(0, 0, 0, 0.6)'}}>
                  &euro;
                  {item.origin_price}
                </h5>
                <h5 className='mb-0 fs-24 lh-1 text-center mb-2' style={{color: '#000000'}}>
                  &euro;
                  {item.sale_price}
                </h5>
              </div>
            </div>
          </div>
        </div>
      )
    });

    return (
      <div>
        <div className='card mx-15'>
          <div className='card-header flex-column justify-content-center'>
            <h1 className='mb-0'>
              Session Packages
            </h1>
            <div 
              role='button' 
              id='muted-text-group' 
              style={{right: 34}}
              onClick={() => this.openAddPackageHandler()} 
              className='position-absolute d-flex align-items-center justify-content-center cursor-pointer font-weight-bold btn-info btn-round py-10 px-20' 
            >
              <i className='ion-plus-round cursor-pointer mr-2'></i>
              Add package
            </div>
          </div>
        </div>
        <div className='mx-15'>
          <Notification notification={notification} />
        </div>
        { isLoading ?
          <div className='d-flex align-items-center justify-content-center' style={{ minHeight: "50vh"}}>
            <div className='text-center'>
              <LoadingGift />
              <p>Collecting packages data</p>
            </div>
          </div>
          : <div className='row mx-auto'>
            { data.length > 0 ? 
              render_packages
              : <div className='card mx-15' style={{width: '100%', minHeight: '50vh'}}>
                <div className='card-body d-flex align-items-center justify-content-center p-20'>
                  <h4>No session packages found.</h4>
                </div>
              </div>
            }
          </div>
        }
        { isEditModalOpen ? 
          <EditSessionPackageModal 
            package_id={selectedPackage._id}
            title={selectedPackage.title}
            description={selectedPackage.description}
            credit_value={selectedPackage.credit_value}
            hours={selectedPackage.hours}
            person={selectedPackage.person}
            is_active={selectedPackage.is_active}
            origin_price={selectedPackage.origin_price}
            sale_price={selectedPackage.sale_price}
            onCloseModal={this.closeEditPackageHandler}
          /> 
        : null }
        { isAddModalOpen ? 
          <AddSessionPackageModal 
            onCloseModal={this.closeAddPackageHandler}
          /> 
        : null }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    modal: state.modal
  };
}

export default connect(mapStateToProps, {userGetAllPackages})(SessionPackages);
