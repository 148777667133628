import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '../../privateRoute';
import Layout from '../layout/Layout';
import Orders from './views/orders';
import PackageOrders from './views/package/packageOrders';
import PackageOrderDetails from './views/package/packageOrderDetails';
import VoucherOrders from './views/voucher/voucherOrders';
import VoucherOrderDetails from './views/voucher/voucherOrderDetails';

class OrdersPage extends React.Component {
	render() {
		const { history, match } = this.props;
		return(
			<Layout history={history}>
                <main className="main-container">
                	<div className="main-content">
						<Switch>
							<PrivateRoute path={`${match.path}/gift-card/details`} component={VoucherOrderDetails} />
							<PrivateRoute path={`${match.path}/gift-card`} component={VoucherOrders}/>
							<PrivateRoute path={`${match.path}/package/details`} component={PackageOrderDetails} />
							<PrivateRoute path={`${match.path}/package`} component={PackageOrders}/>
							<PrivateRoute path={`${match.path}`} component={Orders}/>
							<Redirect to='/orders' />
						</Switch>
                	</div>
                </main>
             </Layout>
		);
	}
}

export default OrdersPage;
