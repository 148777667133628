import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MDSpinner from "react-md-spinner";

/*Import common component*/
import validateInput from "../common/validations/forgotPassword";
import TextFieldGroupLogin from "../common/TextFieldGroupLogin";
import Notification from "../common/Notification";

/*Import redux */
import { connect } from "react-redux";
import { userForgotPassword } from "../../actions/authActions";

class forgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
      isLoading: false,
      notification: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  /*Input validation*/
  isValid() {
    const { errors, isValid } = validateInput(this.state);
    if (!isValid) {
      this.setState({ errors });
    }
    return isValid;
  }

  /*On submit action*/
  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.setState({ errors: {}, isLoading: true, notification: {} });
      this.props
        .userForgotPassword(this.state)
        .then((response) => {
          console.log(response);
          if (response.status !== 200) {
            this.setState({
              isLoading: false,
              email: "",
              notification: {
                text: response.message,
                isError: true,
              },
            });
          } else {
            // this.props.history.push(`/reset-password?token=${response.message.token}&email=${response.message.email}`);
            this.setState({
              isLoading: false,
              email: "",
              notification: {
                text: response.message.message,
                isError: false,
              },
            });
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            email: "",
            notification: {
              text: "Internal Server Error, please try again later",
              isError: true,
            },
          });
        });
    }
  }

  render() {
    const { errors, email, isLoading, notification } = this.state;
    return (
      <div className="card card-round card-shadowed px-50 py-30 w-400px mb-0">
        <img
          className="logo-login"
          src="/assets/img/logo.png"
          alt="logo icon"
        />
        <div className="form-group">
          <Notification notification={notification} />
        </div>
        <p>
          <small>We'll send you instructions via email</small>
        </p>
        <form className="form-type-material" onSubmit={this.onSubmit}>
          <TextFieldGroupLogin
            error={errors.email}
            label="Email"
            onChange={this.onChange}
            value={email}
            field="email"
          />
          <div className="form-group">
            <button
              disabled={isLoading}
              className="btn btn-bold btn-block btn-main-pink"
            >
              {isLoading ? <MDSpinner /> : "Reset password" }
            </button>
            <Link
              to="/login"
              disabled={isLoading}
              className="btn btn-bold btn-block btn-main-transparent"
            >
              <span>Login</span>
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

/*Required propTypes*/
forgetPassword.propTypes = {
  userForgotPassword: PropTypes.func.isRequired,
};

export default connect(null, { userForgotPassword })(forgetPassword);
