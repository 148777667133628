import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export function validateInputAddAccount(data) {
	let errors = {};
	
	if(!isEmpty(data.email)){
        if(!Validator.isEmail(data.email)){
            errors.email = "Email is not valid";
        }
    } else {
       errors.email = "Please insert a valid email";
	}
	if(isEmpty(data.phone)){
		errors.phone = "Please insert your phone number";
	}
    if(isNaN(data.phone)) {
        errors.phone = 'Please insert number'
    }
	if(isEmpty(data.first_name)){
		errors.first_name = "First name is required";
    }
	if(isEmpty(data.last_name)){
		errors.last_name = "Last name is required";
    }
	
	return {
		errors,
		isValid: isEmpty(errors)
	}
};

export function validateInputEditAccount(data) {
	let errors = {};
	
	if(!isEmpty(data.email)){
        if(!Validator.isEmail(data.email)){
            errors.email = "Email is not valid";
        }
    } else {
       errors.email = "Please insert a valid email";
	}
	if(isEmpty(data.phone)){
		errors.phone = "Please insert phone number";
	}
    if(isNaN(data.phone)) {
        errors.phone = 'Please insert number'
    }
	if(isEmpty(data.name)){
		errors.name = "Name is required";
    }
	
	return {
		errors,
		isValid: isEmpty(errors)
	}
};

export function validateChangePassword(data) {
	let errors = {};

	if(isEmpty(data.password)){
		errors.password = "Old password is required";
	}
	if(!isEmpty(data.new_password) && data.new_password.length < 6){
		errors.new_password = "Please insert min. 6 characters";
    }
	if(isEmpty(data.new_password)){
		errors.new_password = "New password is required";
    }
	if(isEmpty(data.repeat_password)){
		errors.repeat_password = "Please confirm your new password";
    }
	if(data.new_password !== data.repeat_password){
		errors.repeat_password = "Password doesn't match";
    }
	return {
		errors,
		isValid: isEmpty(errors)
	}
};