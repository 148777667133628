import { API } from '../utils/apiConnect';
import { SET_USERS_TAB_ACTIVE } from './types';

export function userGetTutorsList(data) {
	return dispatch => {
		return API.post('/tutor-crm/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetSingleTutor(data) {
	console.log('data', data);
	return dispatch => {
		return API.post('/tutor-crm/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetTutorAppointmentHistory(data) {
	return dispatch => {
		return API.post('/tutor-crm/appointments-history', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userEditTutor(data) {
	return dispatch => {
		return API.post('/tutor-crm/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userUpdateActiveStatus(data) {
	return dispatch => {
		return API.post('/tutor-crm/edit-account-status', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userDeleteTutor(data) {
	return dispatch => {
		return API.post('/tutor-crm/delete', data)
		.then( res => {
			return res.data;
		});
	}
}

export function setUsersTabActive(tab_id) {
	return {
		type : SET_USERS_TAB_ACTIVE,
		tab_id
	};
}

