import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import Notification from "../../common/Notification";
import TextAreaGroup from '../../common/TextAreaGroup';
import TextFieldGroup from "../../common/TextFieldGroup";
import ReactSelectFieldGroup from '../../common/ReactSelectFieldGroup';

import { connect } from 'react-redux';
import { validateEditPackage } from '../../common/validations/packageEdit';
import { userEditPackage } from '../../../actions/packageActions';


class EditSessionPackageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notification: {},
      errors: {},
      package_id: this.props.package_id,
      title: this.props.title,
      description: this.props.description,
      credit_value: this.props.credit_value,
      hours: this.props.hours,
      person: null,
      is_active: this.props.is_active,
      status: this.props.is_active ? { value: true, label: 'Active' } : { value: false, label: 'Non-active'} ,
      origin_price: this.props.origin_price,
      sale_price: this.props.sale_price,
    };
  };

  componentDidMount = () => {
    const { person } = this.props;
    if(person) {
      const parsePerson = {
        value: person,
        label: `${person} ${Number(person) === 1 ? 'person' : 'persons'}`
      };

      this.setState({ person: parsePerson })
    }
  };

  handleAddBenefit = () => {
    this.setState({
      benefit_list: this.state.benefit_list.concat([
        {
          text: "",
          bold_text: false,
          error: '',
        }
      ])
    });
  };

  onChangeBenefitList = (e, idx) => {
    const new_benefit_list = this.state.benefit_list.map((item, sidx) => {
      if (parseInt(idx, 10) !== sidx) {
        return item;
      } else {
        return { 
          ...item,
          text: e.target.value,
        };
      }
    });
    this.setState({ benefit_list: new_benefit_list });
  };
  
  onChangeInput = e => {
    this.setState({ 
      [e.target.name] : e.target.value,
      errors: {
        ...this.state.errors,
        [e.target.name] : null
      }
    });
  };

  onChangeSelect = val => {
    this.setState({
      status: val,
      is_active: val.value
    });
  };

  onChangePerson = val => {
    this.setState({
      person: val,
    });
  };

  isValid = () => {
    const { errors, isValid } = validateEditPackage(this.state);
    if(!isValid){
      this.setState({ errors });
    }
    return isValid;
  };

  onSubmitEditPackage = e => {
    e.preventDefault();
    this.setState({ isLoading: true, notification: {}, errors: {}});

    const { 
      package_id,
      title,
      description,
      credit_value,
      hours,
      person,
      is_active,
      origin_price,
      sale_price
    } = this.state;

    const dataToSubmit = {
      _id: package_id,
      isSingleSession: true,
      data: {
        title: title,
        description: description,
        credit_value: Number(credit_value),
        hours: Number(hours),
        person: person.value,
        is_active: is_active,
        origin_price: Number(origin_price),
        sale_price: Number(sale_price),
      }
    };
    
    if(this.isValid()) {
      this.props.userEditPackage(dataToSubmit).then(res => {
        if(res.status !== 200) {
          this.setState({ 
            isLoading: false,
            notification: {
              isError: true,
              text: res.message,
            }
          });
        } else {
          this.setState({ isLoading: false });
          this.props.onCloseModal();
        }
      });
    } else {
      this.setState({
        isLoading: false,
        notification: {
          isError: true,
          text: "Please complete the form above"
        }
      })
    }
  };

  render() {
    const { 
      title,
      description,
      credit_value,
      hours,
      person,
      status,
      origin_price,
      sale_price,
      isLoading,
      errors,
      notification,
    } = this.state;

    const status_options = [
      { value: true, label: 'Active' },
      { value: false, label: 'Non-active'}
    ];

    const person_options = [
      { value: 1, label: "1 person" },
      { value: 2, label: "2 persons" }
    ];

    return (
      <div>
        <div
          className="modal modal-center fade show"
          id="modal-center"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content" style={{ width: "692px"}}>
              <div className="modal-header">
                <h4 className="modal-title text-center font-weight-bold">Edit Package</h4>
                <button onClick={this.props.onCloseModal} type="button" className="close" data-dismiss="modal">
                  <span aria-hidden="true">
                    <i className="ion-close-round fs-16 cursor-pointer"></i>
                  </span>
                </button>
              </div>
              <div className='modal-body package-modal m-0 py-30 px-40'>
                <TextFieldGroup
                  field="title"
                  label="Title"
                  value={title}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.title}
                />
                <TextAreaGroup
                  field="description"
                  label="Description"
                  value={description}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.description}
                />
                <TextFieldGroup
                  type="credits"
                  field="credit_value"
                  label="Credits"
                  value={credit_value}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.credit_value}
                />
                <TextFieldGroup
                  type="hours"
                  field="hours"
                  label="Hours"
                  value={hours}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.hours}
                />
                <ReactSelectFieldGroup
                  label="Person"
                  value={person}
                  field="person"
                  options={person_options}
                  onChange={this.onChangePerson}
                  isDisabled={isLoading}
                  isSearchable={false}
                  isClearable={false}
                  error={errors.person}
                />
                <TextFieldGroup
                  type="price"
                  field="sale_price"
                  label="Discount price"
                  value={sale_price}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.sale_price}
                />
                <TextFieldGroup
                  type="price"
                  field="origin_price"
                  label="Origin price"
                  value={origin_price}
                  placeholder="Type something"
                  onChange={this.onChangeInput}
                  disabled={isLoading}
                  error={errors.origin_price}
                />
                <ReactSelectFieldGroup
                  label="Status"
                  value={status}
                  field="status"
                  options={status_options}
                  onChange={this.onChangeSelect}
                  isDisabled={isLoading}
                  isSearchable={false}
                  isClearable={false}
                  error={errors.status}
                />
                <Notification notification={notification} />
              </div>
              <div className='modal-footer p-20'>
                { isLoading ? 
                  <div className='d-flex align-items-center justify-content-end'>
                    <MDSpinner />
                  </div>
                  : <div className='d-flex align-items-center justify-content-end'>
                    <button onClick={this.onSubmitEditPackage} className='btn btn-main-orange btn-round letter-spacing-0 mr-2'>Save changes</button>
                    <button onClick={this.props.onCloseModal} className='btn border-orange btn-round letter-spacing-0'>Cancel</button>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  };
};

export default connect(null, { userEditPackage })(EditSessionPackageModal);
