import React, { Component } from "react";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Notification from '../../../common/Notification';

import { connect } from "react-redux";
import { userGetStudentAppointmentsHistory } from '../../../../actions/studentsActions';

class StudentHistoryAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      student_id: this.props.student_id,
      data: [],
      pages: 1,
      isLoading: false,
      selected: {},
      totalAppointments: 0,
      sync: false,
      notification: {},
      errors: {},
    };
    this.table = React.createRef();
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getStudentsList(this.table.current.state);
      }
      return true;
    }
    return true;
  };

  getStudentsList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetStudentAppointmentsHistory({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
      student_id: this.state.student_id
    }).then((res) => {
      if (res.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "We are experiencing technical difficulties at the moment, please try again later",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: res.message.data,
          loading: false,
          pages: res.message.totalPage,
          totalAppointments: res.message.totalAppointments,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };
  
  render() {
    const {
      data,
      pages,
      isLoading,
      notification,
      totalAppointments,
    } = this.state;

    return (
      <div className="card card-body p-30">
        <h1 className="text-center">Appointments history</h1>
        <div className="main-view">
          <p className="text-right fs-16 fw-500 mr-10 py-10">
            Total : {totalAppointments} {totalAppointments > 1 ? 'appointments' : 'appointment'}
          </p>
          <Notification notification={notification} />
          <ReactTable
            filterable
            defaultFiltered={
              this.props.salesTableFilter ? this.props.salesTableFilter : []
            }
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={[
              {
                Header: "Session ID",
                accessor: "booking_id",
                sortable: false,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["booking_id"] }),
                filterAll: true,
                style: { textAlign: "center" },
              },
              {
                Header: "Tutor first name",
                accessor: "tutor_id.first_name",
                sortable: false,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["first_name"] }),
                filterAll: true,
                style: { textAlign: "center" },
              },
              {
                Header: "Tutor last name",
                accessor: "tutor_id.last_name",
                sortable: false,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["last_name"] }),
                filterAll: true,
                style: { textAlign: "center" },
              },
              {
                Header: "Session date ( MM/DD/YYYY )",
                accessor: "appointment_date",
                sortable: false,
                filterAll: true,
                filterable: true,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ["appointment_date"],
                  }),
                Cell: (props) => {
                  return <span>{props.row.appointment_date}</span>;
                },
                style: {
                  textAlign: "center",
                  flex: "264.562 0 auto!important",
                  width: "264.562px!important",
                  maxWidth: "264.562px!important",
                },
              },
              {
                Header: "Type of session",
                accessor: "session_type.value",
                Filter: ({ filter, onChange }) => {
                  return (
                    <select
                      className='form-control'
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%", height: "100%" }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">All</option>
                      <option value="live">Live</option>
                      <option value="skype">Online</option>
                      <option value="children">Kids</option>
                    </select>
                  )
                },
                Cell: (row) => {
                  return (
                    <span>
                      <span className="ml-2">
                        { 
                          row.value === 'live' ? 'Live Session' :  
                          row.value === 'skype' ? 'Online Session' : 
                          'Kids Session'
                        }
                      </span>
                    </span>
                  )
                },
                style: { textAlign: "center" },
              },
              {
                Header: "Order status",
                accessor: "booking_status",
                Filter: ({ filter, onChange }) => (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    style={{ width: "100%", height: "100%" }}
                    value={filter ? filter.value : ""}
                  >
                    <option value="">All</option>
                    <option value="open">New</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="success">Completed</option>
                    <option value="cancel">Cancelled</option>
                    <option value="expired">Expired</option>
                  </select>
                ),
                Cell: (row) => (
                  <span
                    className="text-capitalized fw-500"
                    style={{
                      color:
                        row.value === "open"
                          ? "#0d8fe0"
                          : row.value === "success" 
                          ? "#288a42"
                          : row.value === "confirmed"
                          ? "#15db1f"
                          : row.value === "cancel"
                          ? "red"
                          : "gray",
                    }}
                  >
                    {row.value === "open"
                      ? "New"
                      : row.value === "confirmed"
                      ? "Confirmed"
                      : row.value === "success"
                      ? "Completed"
                      : row.value === "cancel"
                      ? "Cancelled"
                      : "Expired"}
                  </span>
                ),
                style: { textAlign: "center" },
              },
              // {
              //   Header: "Confirmation status",
              //   accessor: "isConfirmed",
              //   Filter: ({ filter, onChange }) => (
              //     <select
              //       onChange={(event) => onChange(event.target.value)}
              //       style={{ width: "100%", height: "100%" }}
              //       value={filter ? filter.value : ""}
              //     >
              //       <option value="">All</option>
              //       <option value="true">Confirmed</option>
              //       <option value="false">Waiting for confirmation</option>
              //     </select>
              //   ),
              //   Cell: (row) => (
              //     <span className="text-capitalized fw-500">
              //       <span
              //         style={{
              //           color: row.value ? "#15db1f" : "yellow",
              //           transition: "all .3s ease",
              //           marginRight: 10,
              //         }}
              //       >
              //         &#x25cf;
              //       </span>
              //       {row.value ? "Confirmed" : "Pending"}
              //     </span>
              //   ),
              //   style: { textAlign: "center" },
              // },
              {
                Header: "Duration",
                accessor: "session_duration",
                sortable: false,
                filterable: false,
                Cell: (row) => {
                  return (
                    <span>
                      <span className="ml-2">{row.value} minutes</span>
                    </span>
                  );
                },
                style: { textAlign: "center" },
              },
              {
                Header: "Credit charged",
                accessor: "book_price",
                sortable: false,
                filterable: false,
                Cell: (row) => (
                  <span>
                    <span className="ml-2">{row.value} flowently credits</span>
                  </span>
                ),
                style: { textAlign: "center" },
              },
            ]}
            ref={this.table}
            data={data} // should default to []
            pages={pages} // should default to -1 (which means we don't know how many pages we have)
            loading={isLoading}
            manual // informs React Table that you'll be handling sorting and pagination server-side
            onFetchData={this.getStudentsList}
            defaultPageSize={10}
            showPagination={true}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  };
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    modal: state.modal,
  }
};

export default connect(mapStateToProps, { userGetStudentAppointmentsHistory })(StudentHistoryAppointment);