import React, { Component } from 'react';
import MDSpinner from 'react-md-spinner';

import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Pagination from '../../../common/Pagination';
import AddLanguageModal from './addLanguageModal';
import EditLanguageModal from './editLanguageModal';
import SearchFieldGroup from '../../../common/SearchFieldGroup';

import { connect } from 'react-redux';
import { userGetLanguageList, userDeleteLanguage } from '../../../../actions/commonActions'; 

const SweetAlert = withReactContent(swal);

class Languages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			notification: {},
			search_language: "",
			data: [],
			filtered: [],
			isOpenAddLanguageModal: false,
			isOpenEditLanguageModal: false,
			isOpenDeleteModal: false,
			selected_edit_lang: {},
			selected_language: {}, // for delete language purpose
			// PAGINATION //
			currentPage: 0,
			resultPerPage: 12,
			totalLanguages: 0,
			totalPage: 1
		};
	};

	shouldComponentUpdate(nextProps,nextState) {
    if(nextState.currentPage !== this.state.currentPage){
      this.setState({
        currentPage: nextState.currentPage
      })
    }
    return true;
	};
	
	componentWillMount = () => {
    const { currentPage, resultPerPage, filtered } = this.state;
		
    this.getAllLanguages({
      pagination: {
        pageSize: resultPerPage, 
        page: currentPage
      },
      filtered
    });
  };
	
	getAllLanguages = (dataToSubmit) => {
		this.setState({ isLoading: false, notification: {} })
		this.props.userGetLanguageList(dataToSubmit).then(res => {
			if(res.status === 200) {
				const sorted_data = res.message.data;
				
				this.setState({
					isLoading: false,
					data: sorted_data,
					totalLanguages: res.message.totalLanguages,
					totalPage: res.message.totalPage
				})
			} else {
				this.setState({ 
					isLoading: false,
					notification: {
						isError: true,
						text: res.message
					}
				});
			};
		}).catch(err => {
			this.setState({
				isLoading: false,
				notification: {
					isError: true,
					text: 'Internal server error. Please try again later'
				}
			});
		});
	};

	onChangeSearchInput = (e) => {
		this.setState({ 
			currentPage: 0,
			search_language : e.target.value,
			filtered: [e.target.value]
		});
		this.getAllLanguages({
			pagination: {
				pageSize: this.state.resultPerPage, 
				page: 0
			},
			filtered: [e.target.value],
		})
	};

	setPaginate = (pageNumber) => {
    const { resultPerPage, filtered } = this.state;
    this.setState({currentPage: pageNumber-1});
    this.getAllLanguages({
      pagination: {
        pageSize: resultPerPage,
        page: pageNumber-1
      },
      filtered,
    });
	};
	
	openAddLanguageModal = () => {
		this.setState({ isOpenAddLanguageModal: true });
	};

	closeAddLanguageModal = () => {
		this.setState({ isOpenAddLanguageModal: false });
	};
  
	openDeleteModal = (selected_language) => {
		this.setState({ isOpenDeleteModal: true, selected_language });

		SweetAlert.fire({
			title:"",
			text: `It will permanently delete language data. Are you sure you want to delete "${selected_language.label}" ?`,
			confirmButtonText: "Yes",
			confirmButtonColor: "red",
			cancelButtonText: "No",
			showCancelButton: true,
		}).then((result) => {
			if( result.isConfirmed ) {
				const { selected_language, resultPerPage, currentPage, filtered } = this.state;
				this.props.userDeleteLanguage({ selected_language }).then(res => {
						if(res.status === 200) {
					this.setState({ isOpenDeleteModal: false });
					this.getAllLanguages({
					pagination: {
						pageSize: resultPerPage, 
						page: currentPage
					},
					filtered
					});
						} else {
							this.setState({ 
					isOpenDeleteModal: false,
								notification: {
									isError: true,
									text: res.message
								}
							});
						};
					}).catch(err => {
				console.log(err);
						this.setState({
					isOpenDeleteModal: false,
							notification: {
								isError: true,
								text: 'Internal server error. Please try again later'
							}
						});
					});
			}
		})
	};

	openEditModal = (selected_language) => {
		this.setState({
			selected_edit_lang: selected_language,
			isOpenEditLanguageModal: true
		});
	};

	closeEditModal = () => {
		this.setState({
			selected_edit_lang: {},
			isOpenEditLanguageModal: false
		});
	};
	
  render() {
		const {
			isLoading,
			isOpenAddLanguageModal,
			isOpenEditLanguageModal,
			search_language,
      		selected_language,
			selected_edit_lang,
			data,
			totalPage,
			currentPage,
			resultPerPage,
			totalLanguages
		} = this.state;

		// const { history, match } = this.props;

    const render_languages = data.map((language, idx) => {
      return (
			<div className='col-md-2 px-2' key={`language-card-${idx+1}`}>
				<div className='card card-body py-20 px-2'>
					<div className='d-flex align-items-center justify-content-between pl-20 pr-10'>
						<p className='mb-0 text-center fs-15 mr-auto'>{language.label}</p>
						<button onClick={() => this.openEditModal(language)} className='btn btn-info px-10 py-11 mr-2' style={{borderRadius: 10}}>
							<i className='fa fa-edit'></i>
						</button>
						<button onClick={() => this.openDeleteModal(language)} className='btn btn-danger px-10 py-' style={{borderRadius: 10}}>
							<i className='fa fa-trash'></i>
						</button>
					</div>
				</div>
			</div>
      	);
		});
		
    return (
      <div className='px-30 py-20'>
				<div className="d-flex align-items-center justify-content-between">
          <h3 className='mx-0 mb-10  pl-12'>Languages</h3>
					<h4 className='mb-0 font-weight-normal'>Total languages : {totalLanguages}</h4>
        </div>
				<div className='d-flex align-items-center justify-content-between'>
					<div className='w-300px'>
						<SearchFieldGroup 
							name='search_language'
							value={search_language}
							onChange={this.onChangeSearchInput}
							addedFormClass='bg-light-gray search-tutor-name fs-14'
							placeholder='Search language(s)'
						/>
					</div>
					<button onClick={this.openAddLanguageModal} className='btn btn-main-orange fs-15 py-2 px-30' style={{borderRadius: 25}}> 
						<i className='fa fa-plus fs-15 mr-2'></i>
						Add language
					</button>
				</div>
				{ isLoading ? 
					<div className='row mx-auto my-30 justify-content-center'>
						<MDSpinner />
					</div>
					: data && data.length > 0 ? 
						<div>
							<div className='row mx-auto mb-0 mt-30' style={{minHeight: '188px'}}>
								{render_languages}
							</div>
							<Pagination 
								totalPages={totalPage}
								activePage={currentPage+1}
								pageLimit={resultPerPage}
								totalRecords={totalLanguages}
								paginate={this.setPaginate}
								onClickPrevious={() => this.setState({ currentPage: currentPage-1 })}
								onClickNext={() => this.setState({ currentPage: currentPage+1 })}  
							/>
						</div>
					: <div style={{height: '100px'}} className='d-flex justify-content-center align-items-center flex-column'>
						<div className='text-center'>
							<p className='fs-18 mb-0 lh-3'>No language match with your search filter.</p>
						</div>
					</div>
				}
				{ isOpenAddLanguageModal ? 
					<AddLanguageModal 
						getLanguageList={this.getAllLanguages}
						closeModal={this.closeAddLanguageModal}
					/>
				: null }
				{ isOpenEditLanguageModal ? 
					<EditLanguageModal 
						selectedLang={selected_edit_lang}
						getLanguageList={this.getAllLanguages}
						closeModal={this.closeEditModal}
					/>
				: null }
			</div>
    );
  }
}

export default connect(null, { userGetLanguageList, userDeleteLanguage })(Languages);