import { API } from '../utils/apiConnect';

export function userEditAccount(data) {
  return (dispatch) => {
    return API.post("/accounts/change-profile", data).then((res) => {
      return res.data;
    });
  };
};

export function userChangePassword(data) {
  return (dispatch) => {
    return API.post("/accounts/change-password", data).then((res) => {
      return res.data;
    });
  };
};
