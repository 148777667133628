import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TextAreaGroup = ({ field, value, label, error, type, onChange, disabled }) => {
	return (
		<div className={classnames("form-group row", {'has-error': error})}>
			<label className="col-form-label col-md-3">{label}</label>
			<div className="col-form-label px-0 col-md-1 d-flex align-items-start justify-content-center">
				<p className='mb-0'>:</p>
			</div>
			<div className="col-md-8 pl-0">
				<textarea
					value = {value}
					onChange = {onChange}
					style={{
						borderColor: error ? 'red' : '#e7e7e7'
					}}
					type={type}
					name={field}
					className="form-control h-140px"
					disabled={disabled}
				/>
			{ error && 
				<span 
					style={{
						color: "#f96868",
						fontStyle: "italic",
						fontSize: "10.5px",
						lineHeight: "20px",
						marginLeft: "3px"
					}}
				>
					{error}
				</span>
			}
			</div>
		</div>
	);
}

TextAreaGroup.propTypes = {
	field: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	error: PropTypes.string,
	type: PropTypes.string.isRequired,
}


TextAreaGroup.defaultProps = {
	type: 'textarea'
}


export default TextAreaGroup;