import { API } from '../utils/apiConnect';

export function userGetAppointmentList(data) {
	return dispatch => {
		return API.post('/appointment-crm/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetSingleAppointment(data) {
	return dispatch => {
		return API.post('/appointment-crm/single', data)
		.then( res => {
			return res.data;
		});
	}
}
