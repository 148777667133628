import { combineReducers } from 'redux';
import auth from './reducers/auth';
import token from './reducers/token';
import data from './reducers/data';
import modal from './reducers/modal';
import tab from './reducers/tab';
import tableFilter from './reducers/tableFilter';

export default combineReducers({
	auth,
	token,
	data,
	modal,
	tab,
	tableFilter,
});