import React from 'react'
import TextFieldDetailsGroup from '../../../common/TextFieldDetailsGroup';

const StudentVoucherOrderDetailsModal = ({ onCloseModal, data }) => {
  let package_name = '';

  if(data && data.description) {
    package_name = data.description.charAt(0).toUpperCase()+data.description.substr(1).toLowerCase()
  };
  
  let payment_status_background = '';
  
  if(data && data.payment_status) {
    payment_status_background = data.payment_status === 'paid' || data.payment_status === 'authorized' ? 'status-box-success' 
    : data.payment_status === 'failed' ? 'status-box-failed' 
    : data.payment_status === 'pending' ? 'status-box-pending' 
    : data.payment_status === 'open' ? 'status-box-open' 
    : data.payment_status === 'canceled' ? 'status-box-canceled'
    : 'status-box-gray'
  };

  return (
    <div id="student-transaction-details-modal">
			<div className="modal modal-center fade show" id="modal-center" style={{display: 'block', paddingRight: 15}}>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header px-30">
							<h5 className="modal-title">Voucher Order Details</h5>
							<button onClick={onCloseModal} type="button" className="close cursor-pointer">
								<span className='cursor-pointer' aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body" style={{ overflowY: "auto!important" }}>
              <div className='d-flex align-items-center justify-content-between px-15'>
                <h3 className='mb-0'>Order ID : { data && data.order_id ? data.order_id : '-' }</h3>
                <div className={`${payment_status_background} mb-0`}>{ data && data.payment_status ? data.payment_status : '-' }</div>
              </div>
              <hr className='mt-20 mb-30' />
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    label='Package name' 
                    value={data && data.description ? package_name : '-'} 
                  />
                </div>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    label='Amount' 
                    currency='&euro;'
                    value={data && data.amount ? data.amount.value : '-'} 
                  />
                </div>
              </div>
              <hr className='mt-0 mb-10' />
              <h3 className='mb-20 px-15'>Recipient Details</h3>
              <div className='row mx-auto'>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    addStyle={{textTransform: 'capitalize'}}
                    label='First name' 
                    value={data && data.receiver_id.first_name ? data.receiver_id.first_name : '-'} 
                  />
                </div>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    addStyle={{textTransform: 'capitalize'}}
                    label='Last name' 
                    value={data && data.receiver_id.last_name ? data.receiver_id.last_name : '-'} 
                  />
                </div>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    label='Email' 
                    value={data && data.receiver_id.email ? data.receiver_id.email : '-'} 
                  />
                </div>
                <div className='col-md-6'>
                  <TextFieldDetailsGroup 
                    label='Phone' 
                    value={data && data.receiver_id.phone ? data.receiver_id.phone : '-'} 
                  />
                </div>
              </div>
						</div>
					</div>
				</div>
			</div>
			<div className="modal-backdrop fade show" style={{opacity: '0.8!important'}}></div>
		</div>
  );
};

export default StudentVoucherOrderDetailsModal;