import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
const StyledCheckbox = styled.div`
  background: ${props => (props.checked ? '#f37a4f' : 'white')};
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: ${props =>
    props.checked ? 'solid 2.1px var(--mainPink)' : props.error ? 'solid 2.1px red' : 'solid 2.1px #e2e2e2'};
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`
const CustomCheckbox = ({ className, checked, disabled, error, ...props }) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox disabled={disabled} checked={checked} {...props} />
      <StyledCheckbox checked={checked} error={error}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default CustomCheckbox
