import { API } from '../utils/apiConnect';

export function userGetPackageOrderList(data) {
	return dispatch => {
		return API.post('/order-crm/package-list', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userGetVoucherOrderList(data) {
	return dispatch => {
		return API.post('/order-crm/voucher-list', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userCheckPackageOrderDetails(data) {
	return dispatch => {
		return API.post('/order-crm/package-single', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userCheckVoucherOrderDetails(data) {
	return dispatch => {
		return API.post('/order-crm/voucher-single', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userCancelPackageOrder(data) {
	return dispatch => {
		return API.post('/order-crm/package-cancel', data)
		.then( res => {
			return res.data;
		});
	};
};

export function userCancelVoucherOrder(data) {
	return dispatch => {
		return API.post('/order-crm/voucher-cancel', data)
		.then( res => {
			return res.data;
		});
	};
};