import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Select from 'react-select';

class ReactSelectFieldGroup extends React.Component {
	render(){
		const { value, label, error, onChange, options, isDisabled, isSearchable, isClearable } = this.props; 

		return (
			<div className={classnames("form-group row", {'has-error has-danger': error})}>
			<label className="col-form-label col-md-3">{label}</label>
			<div className="col-form-label px-0 col-md-1 d-flex align-items-start justify-content-center">
				<p className='mb-0'>:</p>
			</div>
			<div className="col-sm-8 pl-0">
				<div className='w-200px'>

					<Select
						styles={{
							// ...styles,
							option: (base, state) => ({
								...base,
								fontSize: '14px',
								color: state.isFocused
									? '#000000'
									: state.isSelected
									? '#ffffff'
									: base.color,
								backgroundColor: state.isSelected
									? '#f37a4f'
									: base.color,
								'&:hover': {
									backgroundColor: state.isSelected ? "#f37a4f" : '#f5c0ad79',
									color: state.isSelected ? "#ffffff" : '#000000'
								},
								transition: '0.2s all ease-in-out',
							}),
							control: (base, state) => ({
								...base,
								borderRadius: 25,
								paddingLeft: 12,
								paddingRight: 12,
								minHeight: 42,
								fontSize: 14,
								borderColor: error ? 'red' : "#e7e7e7",
								'&:hover': {
									cursor: 'pointer'
								}
							}),
							indicatorSeparator: (base, state) => ({
								...base,
								backgroundColor: "transparent!important"
							})
						}}
						isDisabled={isDisabled}
						value={value}
						onChange={onChange}
						options={options}
						isClearable={isClearable}
						isSearchable={isSearchable}
						placeholder="Select..."
					/>
				</div>
				{error && <div className="form-control-feedback">{error}</div>}
				</div>
			</div>
		);
	}
}

ReactSelectFieldGroup.propTypes = {
	field: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	error: PropTypes.string,
}


export default ReactSelectFieldGroup;

			
