import React from "react";

/*Import redux*/
import { connect } from "react-redux";
import BookingOverview from "./BookingOverview";
import PackageOrderOverview from "./PackageOrderOverview";
import StudentOverview from "./StudentOverview";
import StudentSignupOverview from "./StudentSignupOverview";
import TutorOverview from "./TutorOverview";
import TutorSignupOverview from "./TutorSignupOverview";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {}
  render() {
    return (
			<div>
				<div className="card card-body">
					<div className="row justify-content-md-center">
						<div className="col-lg-8 col-md-12 text-center">
							<h2>Dashboard</h2>
						</div>
					</div>
				</div>
				<div className='row mx-auto'>
					<div className='col-md-6'>
						<div className="card card-body">
							<TutorSignupOverview />
						</div>
					</div>
					<div className='col-md-6'>
						<div className="card card-body">
							<StudentSignupOverview />
						</div>
					</div>
					<div className='col-md-6'>
						<div className="card card-body">
							<PackageOrderOverview />
						</div>
					</div>
					<div className='col-md-6'>
						<div className="card card-body">
							<BookingOverview />
						</div>
					</div>
					<div className='col-md-6'>
						<div className="card card-body">
							<TutorOverview />
						</div>
					</div>
					<div className='col-md-6'>
						<div className="card card-body">
							<StudentOverview />
						</div>
					</div>
				</div>
			</div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(Dashboard);
