import React from 'react'
import Select from 'react-select'
import classnames from 'classnames'

export default function MultiSelectFieldGroup({
  label,
  error,
  value,
  options,
  onChange,
  isClearable,
  isDisabled,
  isSearchable,
}) {
  return (
    <div className={classnames('form-group', { 'has-error has-danger': error })}>
      <label className="col-form-label font-weight-bold letter-spacing-0 pl-12 fs-15">{label}</label>
      <Select
        isMulti
        styles={{
          // ...styles,
          option: (base, state) => ({
            ...base,
            fontSize: '14px',
            color: state.isFocused
              ? '#000000'
              : state.isSelected
              ? '#ffffff'
              : base.color,
            backgroundColor: state.isSelected
              ? '#f37a4f'
              : base.color,
            '&:hover': {
              backgroundColor: state.isSelected ? "#f37a4f" : '#ffe5db75',
              color: state.isSelected ? "#ffffff" : '#000000'
            },
            transition: '0.2s all ease-in-out',
          }),
          control: (base, state) => ({
            ...base,
            '&:hover': {
              borerColor: error ? 'red' : '#ebebeb', 
              cursor: 'pointer'
            },
            backgroundColor: "transparent",
            borderRadius: 25,
            borderWidth: error ? '1px' : base.borderWidth,
            borderColor: error ? 'red' : '#ebebeb',
          }),
          valueContainer: (base, state) => ({
            ...base,
            backgroundColor: "transparent!important"
          }),
          multiValueLabel: (base, state) => {
            return ({
              ...base,
              background: "#E9F0FF",
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 4,
              paddingBottom: 4,
              fontSize: 14,   
              borderRadius: 
                state.data.value === 'welcome_session' || 
                state.data.value === 'social_conversation' || 
                state.data.value === 'skype' ||  
                state.data.value === 'live' ? 
                '25px' 
                : '25px 0 0 25px',
            })
          },
          multiValue: (base, state) => ({
            ...base,
            background: 'transparent',
            paddingTop: 2,
            paddingBottom: 2,
          }),
          multiValueRemove: (base, state) => ({
            ...base,
            background: "#D3E1FF",
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
            padding: "0 5px",
            display: 
            state.data.value === 'welcome_session' || 
                state.data.value === 'social_conversation' || 
                state.data.value === 'skype' ||  
                state.data.value === 'live' ? 
            'none' : 'flex',
          })
        }}
        isDisabled={isDisabled}
        value={value}
        onChange={onChange}
        options={options}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder="Select..."
      />
      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  )
}
