import { API } from '../utils/apiConnect';

export function userGetPackageList(data) {
	return dispatch => {
		return API.post('/package-crm/list', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userGetAllPackages() {
	return dispatch => {
		return API.post('/package-crm/all')
		.then( res => {
			return res.data;
		});
	}
}

export function userGetSinglePackage(data) {
	return dispatch => {
		return API.post('/package-crm/single', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userEditPackage(data) {
	return dispatch => {
		return API.post('/package-crm/edit', data)
		.then( res => {
			return res.data;
		});
	}
}

export function userAddPackage(data) {
	return dispatch => {
		return API.post('/package-crm/add', data)
		.then( res => {
			return res.data;
		});
	}
}