import React from 'react';
import ForgetPassword from './forgetPassword';

class forgetPasswordPage extends React.Component {
	render() {
		const { history } = this.props;
		return(
			<div className="min-h-fullscreen center-vh p-20 pace-done">
				<ForgetPassword history={history}/>
			</div>
		);
	}
}


export default forgetPasswordPage;


