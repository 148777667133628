import { SET_TUTORS_TABLE_FILTER, RESET_TABLE_FILTER, SET_STUDENTS_TABLE_FILTER } from '../actions/types';

const initialTableFilterState = {
	tutors: [],
	students: []
}

export default (state = initialTableFilterState, action = {}) => {
	switch(action.type) {
		case SET_TUTORS_TABLE_FILTER:
			return {
				...state,
				tutors: action.filter
			}	
		case SET_STUDENTS_TABLE_FILTER:
			return {
				...state,
				students: action.filter
			}	
		case RESET_TABLE_FILTER:
			return {
                tutors: [],
                students: []
			}	
		default: return state;
	}	
}