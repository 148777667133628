import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tab: this.props.tab_id,
    };
  };

  onChangeActiveOverview = value => {
    this.setState({ active_tab : value });
  };
  
  render() {
    const { 
      active_tab
    } = this.state;

    return (
      <div id='users-overview'>
        <div className='card card-body p-0'>
          <div className="text-center">
            <h2 className='my-20'>Orders overview</h2>
          </div>
          <ul className="nav nav-tabs nav-justified my-40">
            <li className="nav-item mx-2">
              <Link 
                to={`/orders/package`}
                onClick={() => this.onChangeActiveOverview('package')}
                className={`nav-link fs-16 ${active_tab === 'package' ? 'active' : '' }`}
              >
                Package
              </Link>
            </li>
            <li className="nav-item mx-2">
              <Link 
                to={`/orders/gift-card`}
                onClick={() => this.onChangeActiveOverview('gift-card')}
                className={`nav-link fs-16 ${active_tab === 'gift-card' ? 'active' : '' }`}
              >
                Gift card
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };
}

export default Orders;
