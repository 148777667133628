import React from 'react';
import { connect } from 'react-redux';

class Main extends React.Component {
	componentWillMount(){
		const { isAuthenticated } = this.props.auth;
		const pathname = this.props.history.location.pathname;
    if(!isAuthenticated && pathname!== '/reset-password'){
      this.props.history.push('/login')
		} else if (isAuthenticated && pathname === '/login') {
			this.props.history.push('/dashboard')
		} else if (isAuthenticated && pathname === '/') {
			this.props.history.push('/dashboard')
		}
	}
	render() {

		return(
			<div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(Main);
