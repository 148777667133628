import React from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import { matchSorter } from "match-sorter";

import Notification from "../../common/Notification";

/*Import redux*/
import { connect } from "react-redux";
import { userGetAppointmentList } from "../../../actions/appointmentsActions";
// import { setSalesTableFilter } from "actions/tableFilterActions";
// import { loadModal } from "actions/modalActions";
// import { SALES_EDIT_STATUS_MODAL } from "../../modal/modaltypes";

class Appointment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: -1,
      isLoading: false,
      selected: {},
      totalAppointments: 0,
      sync: false,
      notification: {},
      errors: {},
    };
    this.table = React.createRef();
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.modal.sync) {
      if (nextProps.modal.sync !== this.props.modal.sync) {
        this.getAppointmentList(this.table.current.state);
      }
      return true;
    }
    return true;
  }

  // componentWillUnmount() {
  //   this.props.setSalesTableFilter(this.state.table_filter);
  // }

  getAppointmentList = (state, instance) => {
    this.setState({ isLoading: true, table_filter: state.filtered, notification: {} });
    // const fromDate = moment(this.state.from_date).subtract("days", 1);
    // const toDate = moment(this.state.to_date).add("days", 1);
    this.props.userGetAppointmentList({
      pagination: {
        pageSize: state.pageSize,
        page: state.page,
      },
      sorted: state.sorted,
      filtered: state.filtered,
    }).then((response) => {
      if (response.status !== 200) {
        this.setState({
          isLoading: false,
          notification: {
            text: "Some features has been updated, please logout and login again",
            isError: true,
          },
        });
      } else {
        this.setState({
          isLoading: false,
          data: response.message.data,
          loading: false,
          pages: response.message.totalPage,
          totalAppointments: response.message.totalAppointments,
        });
      }
    }).catch((error) => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { match } = this.props;
    const {
      data,
      pages,
      isLoading,
      notification,
      totalAppointments,
    } = this.state;

    return (
      <div className='px-30'>
        <div className="row justify-content-md-center mb-20">
          <div className="col-lg-8 col-md-12 text-center">
            <h2>Appointments</h2>
          </div>
        </div>
        <div className="table-card-body mb-30">
          <div className="main-view">
            <p className="text-right fs-16 fw-500 mr-10 py-10">Total : {totalAppointments} appointments</p>
            <Notification notification={notification} />
            <ReactTable
              filterable
              defaultFiltered={
                this.props.salesTableFilter ? this.props.salesTableFilter : []
              }
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={[
                {
                  Header: "Session ID",
                  accessor: "booking_id",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["booking_id"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Student name",
                  accessor: "student_id.first_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                {
                  Header: "Tutor name",
                  accessor: "tutor_id.first_name",
                  sortable: false,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["first_name"] }),
                  filterAll: true,
                  style: { textAlign: "center" },
                },
                // {
                //   Header: "Type of session",
                //   accessor: "session_type",
                //   Filter: ({ filter, onChange }) => {
                //     return (
                //       <select
                //         className='form-control'
                //         onChange={(event) => onChange(event.target.value)}
                //         style={{ width: "100%", height: "100%" }}
                //         value={filter ? filter.value : ""}
                //       >
                //         <option value="">All</option>
                //         <option value="live">Skype</option>
                //         <option value="skype">Live</option>
                //         <option value="children">Children</option>
                //       </select>
                //     )
                //   },
                //   Cell: (row) => {
                //     return (
                //       <span>
                //         <span className="ml-2">
                //           { row.value.label }
                //         </span>
                //       </span>
                //     )
                //   },
                //   style: { textAlign: "center" },
                // },
                {
                  Header: "Session date ( MM/DD/YYYY )",
                  accessor: "appointment_date",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["appointment_date"] }),
                  Cell: (props) => {
                    return <span>{props.row.appointment_date}</span>;
                  },
                  style: { 
                    textAlign: "center",
                    flex: "264.562 0 auto!important",
                    width: "264.562px!important",
                    maxWidth: "264.562px!important",
                  },
                },
                {
                  Header: "Order date ( MM/DD/YYYY )",
                  accessor: "created_date",
                  sortable: false,
                  filterAll: true,
                  filterable: true,
                  filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["created_date"] }),
                  Cell: (row) => {
                    return <span>{row.value}</span>;
                  },
                  style: { 
                    textAlign: "center",
                    flex: "264.562 0 auto!important",
                    width: "264.562px!important",
                    maxWidth: "264.562px!important",
                  },
                },
                {
                  Header: "Order status",
                  accessor: "booking_status",
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={(event) => onChange(event.target.value)}
                      style={{ width: "100%", height: "100%" }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">All</option>
                      <option value="open">New</option>
                      <option value="confirmed">Confirmed</option>
                      <option value="success">Completed</option>
                      <option value="cancel">Cancelled</option>
                      <option value="expired">Expired</option>
                    </select>
                  ),
                  Cell: (row) => (
                    <span 
                      className='text-capitalized fw-500'
                      style={{
                        color: row.value === "open"
                        ? "#0d8fe0"
                        : row.value === "success" 
                        ? "#288a42"
                        : row.value === "confirmed"
                        ? "#15db1f"
                        : row.value === "cancel"
                        ? "red"
                        : "gray"
                      }}
                    >
                      { row.value === "open"
                        ? "New"
                        : row.value === "confirmed" 
                        ? "Confirmed"
                        : row.value === "success" 
                        ? "Completed"
                        : row.value === "cancel"
                        ? "Cancelled"
                        : "Expired" }
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
                // {
                //   Header: "Confirmation status",
                //   accessor: "isConfirmed",
                //   Filter: ({ filter, onChange }) => (
                //     <select
                //       onChange={(event) => onChange(event.target.value)}
                //       style={{ width: "100%", height: "100%" }}
                //       value={filter ? filter.value : ""}
                //     >
                //       <option value="">All</option>
                //       <option value="true">Confirmed</option>
                //       <option value="false">Pending</option>
                //     </select>
                //   ),
                //   Cell: (row) => (
                //     <span 
                //       className='text-capitalized fw-500'
                //     >
                //       <span style={{
                //         color: row.value ? '#15db1f'
                //         : '#f0e862',
                //         transition: 'all .3s ease',
                //         marginRight: 10,
                //       }}>
                //         &#x25cf;
                //       </span> 
                //       { row.value
                //         ? "Confirmed"
                //         : "Pending" }
                //     </span>
                //   ),
                //   style: { textAlign: "center" },
                // },
                {
                  Header: "Duration",
                  accessor: "session_duration",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <span>
                        <span className="ml-2">
                          { row.value } minutes
                        </span>
                      </span>
                    )
                  },
                  style: { textAlign: "center" },
                },
                {
                  Header: "Credit charged",
                  accessor: "book_price",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <span>
                      <span className="ml-2">
                        { row.value } flowently credits
                      </span>
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
                {
                  Header: "",
                  accessor: "_id",
                  sortable: false,
                  filterable: false,
                  Cell: (row) => (
                    <span>
                      <Link
                        to={{
                          pathname: `${match.path}/details`,
                          state: row.value,
                        }}
                      >
                        <button className="btn-round btn-main-orange border-0 cursor-pointer px-3 py-1">
                          details
                        </button>
                      </Link>
                    </span>
                  ),
                  style: { textAlign: "center" },
                },
              ]}
              ref={this.table}
              data={data} // should default to []
              pages={pages} // should default to -1 (which means we don't know how many pages we have)
              loading={isLoading}
              manual // informs React Table that you'll be handling sorting and pagination server-side
              onFetchData={this.getAppointmentList}
              defaultPageSize={25}
              showPagination={true}
              className="-striped -highlight"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    modal: state.modal
  };
}

export default connect(mapStateToProps, { userGetAppointmentList })(Appointment);
