export const ADD_FLASH_MESSAGE = "ADD_FLASH_MESSAGE";
export const DELETE_FLASH_MESSAGE = "DELETE_FLASH_MESSAGE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_USER = "UPDATE_USER";

export const SET_TOKEN = "SET_TOKEN";
export const SET_DATA = "SET_DATA";

export const SET_USERS_TAB_ACTIVE = "SET_USERS_TAB_ACTIVE";

export const SET_STUDENTS_TABLE_FILTER = "SET_STUDENTS_TABLE_FILTER";
export const SET_TUTORS_TABLE_FILTER = "SET_TUTORS_TABLE_FILTER";
export const RESET_TABLE_FILTER = "RESET_TABLE_FILTER";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";