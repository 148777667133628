import React from 'react';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';

const  DateSelectGroup = ({
  startDate,
  endDate,
  error,
  onChange,
  placeholderText,
  isDisabled,
}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className={classnames('form-group mb-0', { 'has-error has-danger': error })}>
        <div className="input-group">
          <div className="flex-grow-2">
            <DatePicker
              className={
                error ? 'form-control react-datepicker-style px-20 border-danger'
                : 'form-control react-datepicker-style px-20'
              }
              selected={startDate}
              onChange={date => onChange(date, 'start_period')}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              fixedHeight={true}
              scrollableYearDropdown={false}
              showYearDropdown={false}
              showMonthDropdown={true}
              placeholderText={placeholderText ? placeholderText : "From date"}
              popperPlacement="bottom-start"
              disabled={isDisabled}
            />
          </div>
          <i 
            className="fa fa-calendar-o absolute-center"
            style={{
              position: 'absolute',
              top: '28%',
              right: 20
            }}
          ></i>
        </div>
      </div>
      <span className='mx-2'>&mdash;</span>
      <div className={classnames('form-group mb-0', { 'has-error has-danger': error })}>
        <div className="input-group">
          <div className="flex-grow-2">
            <DatePicker
              className={
                error ? 'form-control react-datepicker-style px-20 border-danger'
                : 'form-control react-datepicker-style px-20'
              }
              selected={endDate}
              onChange={date => onChange(date, 'end_period')}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              fixedHeight={true}
              scrollableYearDropdown={false}
              showYearDropdown={false}
              showMonthDropdown={true}
              placeholderText={placeholderText ? placeholderText : "To date"}
              popperPlacement="bottom-start"
              disabled={isDisabled}
            />
          </div>
          <i 
            className="fa fa-calendar-o absolute-center"
            style={{
              position: 'absolute',
              top: '28%',
              right: 20
            }}
          ></i>
        </div>
      </div>
            
      {error && <div className="form-control-feedback">{error}</div>}
    </div>
  )
}

export default DateSelectGroup
